<template>
    <w-autocomplete v-bind="$attrs" v-on="$listeners" :value="val" :items="items" :rules="rules"/>
</template>
<script>
import l from '../../../libs/lang';
import media_setup from '../../../libs/resources-setups/media';

export default {
    props: ['value'],
    data() {
        return {
            items: [],
            val: null,
            rules: [],
      };
    },
    created() {
        this.$data.val = this.$props.value;
        const items = [];
        const voices = media_setup.available_voices;
        voices.sort((a, b) => a.split(' / ')[1].localeCompare(b.split(' / ')[1]));
        voices.sort((a, b) => b.includes('US') - a.includes('US'));
        this.$data.rules = [];

        this.$data.rules.push(this.$validation.in(voices, null, l.t('app.invalid-value', 'Invalid value')));
        if (Array.isArray(this.$attrs.rules)) {
            this.$data.rules = this.$data.rules.concat(this.$attrs.rules);
        }

        for (const voice of voices) {
            items.push({
                text: `${voice.split(' / ')[1]} - ${l.t('media.voice', 'Voice')}: ${voice.split(' / ')[0]}`,
                value: voice,
            });
        }
        if (!voices.includes(this.$props.value)) {
            items.unshift({
                text: `${l.t('app.Deprecated-voice', 'Deprecated voice')} - ${this.$props.value.charAt(0).toUpperCase() + this.$props.value.slice(1)}`,
                value: this.$props.value,
            });
        }
        this.items = items;
    },
};
</script>
