<template>
  <div>
    <div class="applications list-page">
      <w-alert v-if="listener.alert" :message="listener.alert.message" :level="listener.alert.level" :closable="listener.enable_close_alert" @closed="listener.alert = null" class="mb-6"/>
      <div v-else-if="listener.successful_deletions || listener.failed_deletions" class="mb-6">
          <w-alert
            v-if="listener.successful_deletions"
            :message="listener.successful_deletions.message"
            level="success"
            :closable="true"
          />
          <w-alert
            v-if="listener.failed_deletions"
            :message="listener.failed_deletions.message"
            level="error"
            :closable="true"
          />
      </div>
      <div v-if="listener.loading">
          <w-loader v-if="!listener.deleting"/>
          <ProgressBar
            v-else
            :key="listener.deleting.status.length"
            :processing="listener.deleting"
            @stopProcess="listener.stopDeletion = true"
            data-test-id="apps-progressbar"
          />
      </div>
      <div v-else>
        <template v-if="listener.items && listener.items.length">
          <v-simple-table class="list-table" data-test-id="apps-table">
            <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                <th data-test-id="checkbox"></th>
                <th data-test-id="application">{{l.t('app.application', 'Application')}}</th>
                <th data-test-id="id">{{l.t('app.ID', 'ID')}}</th>
                <th data-test-id="extension">{{l.t('app.extension', 'Extension')}}</th>
                <th data-test-id="more-options"></th>
              </tr>
            </thead>
            <tbody data-test-id="table-body">
              <tr v-for="(item, i) in listener.items" :key="item['id']" :class="{'selected-row': item.selected}" :data-test-id="`apps-item-${i}-row`">
                <td class="select-table-item" data-test-id="checkbox">
                  <w-checkbox
                    v-model="item.selected"
                    @click="listener.checked($event, item)"
                    :key="`applications-item-${item.id}-${item.selected}`"
                    hide-details="auto"
                    :data-test-id="`apps-item-${i}-checkbox`"
                  />
                </td>
                <td class="nowrap overflow-hidden" data-test-id="list-item">
                  <IndexListItem
                    :route="{
                      name: 'applications.show',
                      params: { id: item['id'] },
                    }"
                    :item="item"
                    :title="$options.filters.upperFirstCase(item[':callback']['config']['application'])"
                    :key="item['id']"
                    :data-test-id="`apps-item-${i}-title`"
                  />
                </td>
                <td :data-test-id="`apps-item-${i}-description`">
                  #{{ item["id"] }}
                </td>
                <td class="w-100" >
                  <template v-if="item['extension_id']">
                    {{ l.t('app.extension', 'Extension') }}: #{{ item['extension_id'] }}
                  </template>
                  <template v-else>&mdash;</template>
                </td>
                <td data-test-id="more-options">
                  <MoreOptions :options="more_options(item)" />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <MassOperationButtons v-if="listener.items.length" @selected="listener.bulkDelete()" @all="listener.deleteAll()" :selected="!listener.disable_delete_button" :filters_applied="listener.filters_applied" :disable_delete_all="true" data-test-id="apps-mass-operations">
              <slot name="additionalMassOperations"></slot>
              <w-checkbox
                v-model="listener.check_all"
                :label="l.t('app.select-all', 'Select all')"
                class="ml-4 select-all"
                hide-details='auto'
                data-test-id="apps-select-all-checkbox"
              />
          </MassOperationButtons>
        </template>
        <NothingFound
          v-else
          :alert="listener.alert"
          :everythingDeleted="listener.everythingDeleted"
          data-test-id="apps-nothing-found"        />
      </div>
    </div>
    <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="listener.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Listener from '../../../models/Listener';
  import ProgressBar from '../../elements/ProgressBar.vue';
  import NothingFound from '../../elements/NothingFound.vue';
  import IndexListItem from '../../elements/IndexListItem.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';
  import MassOperationButtons from '../../elements/MassOperationButtons.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';

  export default {
    components: {
      ProgressBar,
      NothingFound,
      IndexListItem,
      ExtensionSelector,
      MassOperationButtons,
      MoreOptions,
      ConfirmDeletion
    },
    data() {
      return {
        l,
        deleting_item_id: null,
        listener: new Listener(this.$session, vueComponent(this), 'APPLICATION'),
      };
    },
    async created() {
      await this.$data.listener.loadItems();
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.listener.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        return [
          {
            cb: () => this.$router.push({
              name: 'applications.show',
              params: {
                id: item.id,
                _queue: item,
              },
            }),
            title: l.t('app.edit', 'Edit')
          },
          {
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          }
        ];
      },
    },
    filters: {
      upperFirstCase(val) {
        if (!val) return '—';
        return val.charAt(0).toUpperCase() + val.slice(1).split('_').join(' ').split('-')
.join(' ');
      },
    },
  };
</script>
