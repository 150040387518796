/* eslint prefer-destructuring : "off" */
import { formatTime, formatDuration } from 'formatters'
import lang from 'lang'
import exportPdf from 'console/src/libs/pdf-exporter'
import SmartFilters from 'console/src/models/SmartFilters'
import SidebarRouter from 'console/src/libs/sidebar-router'

/**
 *
 */
export default class CallReport extends SmartFilters {
    /**
     *
     * @param {object} session
     * @param {object} component
     * @param {Array} allowed_fields
     * @param {string} type ['summary', 'types', 'traffic', 'geo-distribution', 'total', 'per-extension', 'per-number']
     * @param {string} group_by
     */
    constructor (session, component, allowed_fields, type, group_by) {
        super(session, component, '/call-reports')
        this.allowed_fields = allowed_fields
        this.filters.group_by = group_by
        this.group_by_param = group_by
        if (!['summary', 'types', 'traffic', 'geo-distribution', 'total', 'per-extension', 'per-number'].includes(type)) {
            throw new Error('Unsupported report type.')
        }
        this.type = type
        this.has_deleted_extensions = false
        this.allow_any_time_filter = false
        this.last_time_loaded = null
        this.exporting_pdf = false
        /* eslint-disable */
        this.formatters = {
            price(x) {
                if (!x || x === Infinity || x === -Infinity) return 0;

                return x;
            },
            duration(x) {
                return formatDuration(x);
            },
            time(x) {
                return formatTime(x);
            },
            number: (x) => x,
        };
        /* eslint-enable */

        this._api_items = null
        this.exclude_hour_filter = ['prev_month', 'q1', 'q2', 'q3', 'q4', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
        this.items = []
        this.config_key = 'call-reports-filters'
        this.filters = this.buildDefaultFilters()
        this.empty_filters = JSON.parse(JSON.stringify(this.filters))
        this._fields = {
            min_call_duration: {
                type: 'duration',
                translation: lang.t('calls.min_call_duration-h-m-s-ds', 'Minimum duration (Hours : Minutes : Seconds : Tenth of seconds)')
            },
            avg_call_duration: {
                type: 'duration',
                translation: lang.t('calls.avg_call_duration-h-m-s-ds', 'Averege duration (Hours : Minutes : Seconds : Tenth of seconds)')
            },
            max_call_duration: {
                type: 'duration',
                translation: lang.t('calls.max_call_duration-h-m-s-ds', 'Maximum duration (Hours : Minutes : Seconds : Tenth of seconds)')
            },
            min_amount: {
                type: 'price',
                translation: lang.t('calls.min_amount', 'Minimal amount')
            },
            avg_amount: {
                type: 'price',
                translation: lang.t('calls.avg_amount', 'Average amount')
            },
            max_amount: {
                type: 'price',
                translation: lang.t('calls.max_amount', 'Maximal amount')
            },
            total_amount: {
                type: 'price',
                translation: lang.t('calls.estimated-cost', 'Estimated cost')
            },
            first_time: {
                type: 'time',
                translation: lang.t('calls.first_time', 'First call')
            },
            last_time: {
                type: 'time',
                translation: lang.t('calls.last_time', 'Last call')
            },
            count_incoming: {
                type: 'number',
                translation: lang.t('app.incoming-calls', 'Incoming calls')
            },
            count_outgoing: {
                type: 'number',
                translation: lang.t('app.outgoing-calls', 'Outgoing calls')
            },
            total: {
                type: 'number',
                translation: lang.t('reports.total-calls', 'Total calls')
            }
        }
        this.logarithmic = true
    }

    /**
     *
     */
    set api_items (val) {
        this._api_items = val
    }

    /**
     *
     */
    get api_items () {
        return this._api_items
    }

    /**
     *
     */
    prepare_items () {
        throw new Error('prepare_items method must be defined in child report class.')
    }

    /**
     *
     */
    get title () {
        const report = SidebarRouter.report_routes.routes.find((x) => x.params && x.params.type === this.type)
        if (report) {
            return report.long_title
        }

        return this.type
    }

    /**
     *
     */
    async loadItems () {
        this.loading = true
        try {
            const items = await this.get_all_items()
            this.filters_applied = true
            this.api_items = JSON.parse(JSON.stringify(items.items))
            this.items = items.items.length
                ? await this.prepare_items(items.items)
                : null
            this.last_time_loaded = Date.now()
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     *
     */
    async get_all_items () {
        const limit = 500
        const url = this.buildUrlWithFilters()
        let res = await this.session.get_list(url, limit)
        const items = {
            items: res.items,
            total: res.items.length
        }
        let offset = limit
        while (res.items.length === limit) {
            res = await this.session.get_list(url, limit, offset)
            offset += limit
            items.items = items.items.concat(res.items)
            items.total += res.items.length
        }

        return items
    }

    /**
     *
     */
    buildDefaultFilters () {
        const filters = {
            group_by: this.group_by_param,
            show_all_extensions: false,
            show_deleted_extensions:
                this.type === 'per-extension' ? false : undefined
        }
        const {
            start, end, type, group_by
        } = this.dissabmleFilters()
        filters.end = end
        filters.start = start
        filters.type = type
        filters.direction = null
        if (group_by) filters.group_by = group_by
        return filters
    }

    /**
     *
     */
    dissabmleFilters () {
        this.loading = true
        let start
        let end
        const cache = this.get_cached_filters()
        let filters = cache || null
        if (filters && filters.type) {
            if (filters.type === 'custom') {
                start = new Date(parseInt(filters.start) * 1000)
                end = new Date(parseInt(filters.end) * 1000)
            } else {
                const time = (this.get_filter_by_type_or_default(filters.type)).time()
                start = time.start
                end = time.end
            }
            // the case where we have traffic report and more than month period, filter's type has to be date, types must not be cached
            if (this.type === 'traffic' && this.exclude_hour_filter.includes(filters.type)) filters.group_by = 'date'
        } else {
            filters = this.generateStartAndEndTime(true)
            start = filters.start
            end = filters.end
            filters.type = 'today'
        }
        filters = { ...this.display_time(filters, start, end) }
        return filters
    }

    /**
     *
     * @param {boolean} today
     * @returns
     */
    generateStartAndEndTime (today = false) {
        let type
        if (today) {
            type = 'today'
        } else if (this.saved_filter_name) {
            type = 'custom'
        } else {
            type = this.filters && this.filters.type ? this.filters.type : 'today'
        }
        const filter = this.get_filter_by_type_or_default(type)
        return filter.time()
    }

    /**
     *
     */
    apply_type_range () {
        if (this.type === 'traffic' && this.exclude_hour_filter.includes(this.filters.type)) {
            this.filters.group_by = 'date'
        }
        const { start, end } = (this.get_filter_by_type_or_default(this.filters.type)).time()
        const filters = { ...this.display_time(this.filters, start, end) }
        this.filters.start = filters.start
        this.filters.end = filters.end
        this.component.forceUpdate()
    }

    /**
     *
     * @param {string} type
     * @returns
     */
    formatValue (type) {
        return this.formatters[type]
    }

    /**
     *
     */
    apply_saved_filters () {
        if (!this.saved_filter_name) {
            this.filters = this.empty_filters
            return this.filters
        }
        const filter = this.client_config[this.config_key].find((x) => x.name === this.saved_filter_name)
        this.filters = JSON.parse(JSON.stringify(filter))
        if (this.filters && this.filters.group_by) {
            switch (this.type) {
                    case 'traffic':
                        if (!['hour', 'date', 'week'].includes(this.filters.group_by)) this.filters.group_by = this.group_by_param
                        break
                    case 'total':
                        if (!['minute', 'hour', 'date', 'week', 'extension', 'direction'].includes(this.filters.group_by)) {
                            this.filters.group_by = this.group_by_param
                        }
                        break
                    default:
                        this.filters.group_by = this.group_by_param
            }
        } else {
            this.filters.group_by = this.group_by_param
        }
        this.component.forceUpdate()
        return true
    }

    /**
     *
     */
    buildUrlWithFilters () {
        const filters = this.generateStartAndEndTime()
        let { start, end } = filters
        start = Math.floor(new Date(start).getTime() / 1000)
        end = Math.floor(new Date(end).getTime() / 1000)
        if (this.filters.type === 'today') {
            this.cachier.removeItem(this.reports_filters_cache_key)
        } else {
            const stored_filters = {
                start,
                end,
                type: this.filters.type
            }
            this.cachier.setItem(this.reports_filters_cache_key, stored_filters)
        }
        let url = `/call-reports?filters[created_at]=between:${start},${end}`
        if (this.filters.group_by) {
            url += `&group_by=${this.filters.group_by}`
        }
        if (this.filters.direction && this.type === 'geo-distribution') { // only available on geo-reports
            url += `&filters[direction]=${this.filters.direction}`
        }
        url += `&fields=${Object.keys(this.fields).join(',')}`
        const timezone_offset = new Date().getTimezoneOffset() * 60
        if (timezone_offset) url = `${url}&tz_offset=${timezone_offset}`

        return url
    }

    /**
     *
     */
    clear_filters () {
        this.keep_filters_open = true
        this.saved_filter_name = null
        const { start, end } = (this.get_filter_by_type_or_default('today')).time()
        const filters = { ...this.display_time(this.filters, start, end) }
        this.filters = {
            ...this.empty_filters,
            type: 'today',
            start: filters.start,
            end: filters.end
        }
        this.loadItems()
    }

    /**
     *
     */
    exportCsv () {
        try {
            const filename = this.generateFileName()
            const items = typeof this.prepare_csv_values === 'function' ? this.prepare_csv_values(this.items) : JSON.parse(JSON.stringify(this.items))
            const csv = super.build_csv(items, {}, this.headers)
            CallReport.download_csv(csv, `${filename}.csv`)
            this.csv_downloaded_successfully()
        } catch (err) {
            console.log(err)
            this.alert = {
                message: lang.t(
                    'reports.error-exporting-csv',
                    'An error occurred exporting .csv file.'
                ),
                level: 'error'
            }
            this.hide_alert(5)
        }
    }

    /**
     *
     */
    generateFileName () {
        const title = `${this.title}  ${lang.t('grouped-by', 'grouped by')} ${this.filters.group_by}`
        if (!['custom', 'today'].includes(this.filters.type)) {
            let type = this.get_filter_by_type_or_default(this.filters.type)
            if (type) {
                type = type.translation
                if (!type.match(/(\(|\))/)) type = type.toLowerCase() // has no '(year)' => last week, previous month
                return `${title} ${lang.t('app.for', 'for')} ${type}`
            }
        }
        return `${title} ${lang.t('app.from-lowercase', 'from')} ${this.filters.start} ${lang.t('app.to-lowercase', 'to')} ${this.filters.end}`
    }

    /**
     *
     */
    async exportPdf () {
        this.exporting_pdf = true
        this.alert = null
        try {
            await new Promise((resolve) => { // this approach because if user clicks GET CSV and then fast on GET PDF
                setTimeout(async () => {
                    const filename = this.generateFileName()
                    await exportPdf('pdf-export', filename, this.session)
                    this.pdf_downloaded_successfully()
                    this.exporting_pdf = false
                    return resolve
                }, 1)
            })
        } catch (err) {
            console.error(err)
            this.alert = {
                message: lang.t('app.error-exporting-pdf', 'Something went wrong, exporting PDF failed'),
                level: 'error'
            }
            this.hide_alert(3)
            window.scrollTo(0, 0)
            this.exporting_pdf = false
            return false
        }

        return true
    }

    /**
     *
     */
    get fields () {
        const fields = {}
        for (const prop of this.allowed_fields) {
            if (this._fields[prop]) {
                fields[prop] = this._fields[prop]
            }
        }
        return fields
    }

    /**
     *
     * @param {number} timestamp
     * @returns
     */
    formatTime (timestamp) {
        if (this.filters && ['npa', 'direction', 'extension'].includes(this.filters.group_by)) return timestamp
        const time = new Date(timestamp * 1000)
        switch (this.filters.group_by) {
                case 'hour':
                    return time.toLocaleString()
                case 'minute':
                    return time.toLocaleString()
                default:
                    return time.toLocaleDateString()
        }
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    // eslint-disable-next-line class-methods-use-this
    logarithmic_values (items) {
        // eslint-disable-next-line prefer-spread
        const max = Math.log(Math.max(...items))
        const logs = {}
        for (let i = 0; i < items.length; i++) {
            // eslint-disable-next-line no-continue
            if (logs[items[i]]) continue
            if (items[i]) {
                const log = Math.log(items[i])
                // if (max === log) {
                //     logs[items[i]] = 100;
                // } else {
                logs[items[i]] = log / max
                // }
            } else {
                logs[items[i]] = 0
            }
        }
        return logs
    }
}
