<template>
    <w-loader v-if="loading"/>
    <v-row v-else-if="phone_numbers && phone_numbers.length > 0">
        <v-col
            v-for="(p, i) in phone_numbers" v-bind:key="p['phone_number']"
            xl="4"
            ld="4"
            md="4"
            sm="4"
            xs="6"
        >
            <v-card
                class="mx-auto text-center"
                @click="reserve(p, $event)"
                :data-test-id="`number-picker-list-${i}`"
              >
                <v-card-text>
                  <p class="text-h5 text--primary mb-1" :data-test-id="`number-picker-list-item-phone-number-${i}`">
                    {{ p["phone_number"] | formatPhoneNumber }}
                  </p>
                  <p v-if="p['letters_in_number']" class="number-letters-example mb-1" :data-test-id="`number-picker-list-item-letter-to-numbers-${i}`">
                      ({{ format_phone_number_with_text(p['letters_in_number'], true, p['letters_in_number'], 0) }})
                  </p>
                  <!-- <h4 v-if="!p.meta_info && show_number_meta_info" class="show-num-info">
                      <a @click="loadInfo(i)" class="show-num-info-link">{{ l.t('phone-numbers.show-number-meta', 'Show number meta info') }}</a>
                  </h4> -->
                  <div class="w-subtitle-1 font-weight-bold">
                      <span v-if="p['price'] > 0" class="mb-1" :data-test-id="`number-picker-list-item-price-${i}`">
                          ${{ Math.floor(p["price"]/100) }}
                      </span>
                      <span class="success--text mb-1" v-else :data-test-id="`number-picker-list-item-free-${i}`">
                          {{ l.t("app.free-no-price", "Free") }}
                      </span>
                      <span v-if="p['is_toll_free']" class="mb-1" :data-test-id="`number-picker-list-item-toll-free-${i}`">
                          {{ l.t("number-picker.toll-free", "Toll free") }}
                      </span>
                      <span v-else class="mb-1" :data-test-id="`number-picker-list-item-geography-${i}`">
                          {{ [p["city"], p["region"], p["country"]].filter((v) => !!v).join(", ") }}
                      </span>
                  </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
  import {formatPhoneNumberWithText} from 'formatters';
  import l from '../../libs/lang';

  export default {
    props: ['_phone_numbers'/* , 'show_number_meta_info' */],
    data() {
      const end = new Date();
      const start = new Date();
      start.setDate(end.getDate() - 7);
      return {
        l,
        loading: false,
        phone_numbers: [],
      };
    },
    created() {
        this.$data.phone_numbers = this.$props._phone_numbers;
    },
    // mounted() {
    //     this.$parent.$on('infoLoaded', (val) => {
    //         this.updateMetaInfoForNum(val);
    //     });
    //     this.$parent.$on('infoLoading', (val) => {
    //         const index = this.$data.phone_numbers.findIndex((x) => x.phone_number === val);
    //         if (index > -1) {
    //             this.$data.phone_numbers[index].loading = true;
    //             this.$forceUpdate();
    //         }
    //     });
    // },
    methods: {
      reserve(p, e) {
        if (e && (e.target.classList.contains('show-num-info') || e.target.classList.contains('show-num-info-link'))) return null;
        this.$emit('reserved', {
          phone_number: p['phone_number'],
          search_id: p['search_id'],
        });

        return true;
      },
      format_phone_number_with_text(number, no_code, text, start) {
        console.log('============================');
        console.log(number, no_code, text, start);
        console.log('============================');
        return formatPhoneNumberWithText(number, no_code, text, start);
      },
      // loadInfo(i) {
      //   this.$data.phone_numbers[i].loading = true;
      //   this.$emit('loadInfo', this.$data.phone_numbers[i]['phone_number']);
      //   this.$forceUpdate();
      // },
    //   updateMetaInfoForNum(val) {
    //     try {
    //         let info = null;
    //         if (this.$data.phone_numbers && val && val.length) {
    //             info = val[0];
    //         } else {
    //             for (const num of this.$data.phone_numbers) num.loading = false;
    //             this.$forceUpdate();
    //             return null;
    //         }
    //         const index = this.$data.phone_numbers.findIndex((x) => x.phone_number === info.number);

    //         if (index > -1) {
    //             this.$data.phone_numbers[index]['meta_info'] = info;
    //             this.$data.phone_numbers[index]['loading'] = false;
    //             this.$forceUpdate();
    //         }
    //     } catch (err) {
    //         console.log(err);
    //         for (const num of this.$data.phone_numbers) num.loading = false;
    //         this.$forceUpdate();
    //     }

    //     return true;
    //   },
    },
    filters: {
        capitalize(val) {
            return val.charAt(0).toUpperCase() + val.slice(1);
        },
    },
  };
</script>
