import { formFileToBase64 } from 'helpers'
import l from '../libs/lang'
import SmartFilters from './SmartFilters'
import setup from '../libs/resources-setups/fax'
import EventBus from '../libs/EventBus'

/**
 *
 */
export default class Fax extends SmartFilters {
    /**
     *
     * @param {object} session PhoenixApiJsClient object
     * @param {object} component helpers vueComponent
     */
    constructor (session, component) {
        super(session, component, '/fax')
        this.setup = setup
        this.filters = {
            is_new: false,
            from: null,
            to: null,
            type: 'forever'
        }
        this.apply_type_range()
        this.filters = JSON.parse(JSON.stringify(this.filters))
        this.config_key = 'fax-filters'
        this.send_to = null
        this.send_cover_text = null
        this.send_file = null
        this.send_alert = null
        this.deleting_item_id = null
    }

    /**
     *
     * @param {number} value
     */
    async extensionChanged (value) {
        this.extension = value
        if (value) {
            this.clear_filters()
        } else {
            this.items = []
        }
    }

    /**
     *
     * @param {object} params
     * @returns
     */
    async loadItems (params) {
        if (!this.extension) return null
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            const items = await this.session.get_list(this.uri, params.limit, offset)
            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            this.items = items.items
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     * @param {number} id
     * @returns
     */
    async opened (id) {
        try {
            const message = this.items.find((x) => x.id === id)
            if (message && !message.is_new) return null
            await this.session.patch_item(
                `${this.baseUri}/${id}`,
                { is_new: false }
            )
            for (const item of this.items) {
                if (item.id === id) {
                    item.is_new = false
                    break
                }
            }
        } catch (err) {
            this.alert = {
                level: 'error',
                message: l.t('app.error-occurred-while-updating-your-message-status', 'An error occurred while updating your message status.')
            }
            this.hide_alert(5)
        }

        return true
    }

    /**
     *
     * @param {number} id
     */
    async delete_m (id) {
        this.deleting_item_id = id
        try {
            await this.session
                .delete_item(`${this.baseUri}/${id}`)
            this.items = this.items.filter((v) => v.id !== id)
        } catch (err) {
            this.validation_error(err)
        }
        this.deleting_item_id = null
    }

    /**
     *
     */
    apply_filters () {
        this.filters_applied = true
        let uri = `${this.baseUri}?mode=full`
        if (this.filters.start) {
            const start = Math.floor(Date.parse(this.filters.start) / 1000)
            uri = `${uri}&filters[created_at][]=gte:${start}`
        }
        if (this.filters.end) {
            const end = Math.floor(Date.parse(this.filters.end) / 1000)
            uri = `${uri}&filters[created_at][]=lte:${end}`
        }
        if (this.filters.is_new) {
            uri = `${uri}&filters[is_new]=${this.filters.is_new}`
        }
        if (this.filters.from) {
            uri = `${uri}&filters[from]=${this.filters.from}`
        }
        if (this.filters.to) {
            uri = `${uri}&filters[to]=${this.filters.to}`
        }
        this.uri = uri
        this.hide_filters = false
    }

    /**
     *
     */
    async send () {
        this.loading = true
        const data = {
            to: this.send_to,
            extension: this.extension
        }
        if (this.send_cover_text) {
            data.cover_text = this.send_cover_text
        }
        if (this.send_file) {
            data.media = [{
                name: this.send_file.name,
                data: await formFileToBase64(this.send_file)
            }]
        }
        try {
            await this.session.create_item(this.baseUri, data)
            this.alert = {
                level: 'success',
                message: l.t('fax.sent', 'Fax was sent')
            }
            this.hide_alert(5)
            this.send_to = ''
            this.send_cover_text = ''
            this.send_file = null
            EventBus.$emit('item_updated')
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     *
     * @param {blob} file
     * @returns
     */
    handle_file_upload (file) {
        this.send_alert = null
        this.send_file = null
        if (file) {
            if (file.size > (4096 * 1024)) {
                this.send_alert = {
                    message: l.t('fax.send-too-large', 'File is too large. File size should be smaller than 4M Byte.'),
                    level: 'warning'
                }
                this.hide_alert(5, 'send_alert')
                return null
            }
            this.send_file = file
        }

        return true
    }
}
