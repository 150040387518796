import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {object} props - svg props
 */
export const StorefrontIcon = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath="url(#clip0_1874_3702)">
                <path d="M24.9836 11.8324L23.9245 7.38146C23.7026 6.46479 22.9158 5.83331 21.9979 5.83331H19.7688H17.7414H16.0065H13.9892H12.2543H10.2168H7.98767C7.07988 5.83331 6.28304 6.47498 6.07122 7.38146L5.01213 11.8324C4.77005 12.8713 4.99195 13.9305 5.63749 14.7657C5.71819 14.8778 5.82914 14.9592 5.91992 15.0611V22.1296C5.91992 23.25 6.82771 24.1666 7.93723 24.1666H22.0584C23.168 24.1666 24.0758 23.25 24.0758 22.1296V15.0611C24.1665 14.9694 24.2775 14.8778 24.3582 14.7759C25.0037 13.9407 25.2357 12.8713 24.9836 11.8324ZM9.97472 7.87035L9.3897 12.8203C9.30901 13.4824 8.78451 13.9815 8.16923 13.9815C7.67498 13.9815 7.3623 13.6861 7.23117 13.5028C6.96892 13.1666 6.87814 12.7287 6.97901 12.3111L7.98767 7.87035H9.97472ZM21.9677 7.86016L23.0268 12.3111C23.1276 12.7389 23.0368 13.1666 22.7746 13.5028C22.6334 13.6861 22.3308 13.9815 21.8265 13.9815C21.2112 13.9815 20.6766 13.4824 20.606 12.8203L20.021 7.87035L21.9677 7.86016ZM18.5382 12.4741C18.5887 12.8713 18.4676 13.2685 18.2054 13.5639C17.9734 13.8287 17.6506 13.9815 17.2371 13.9815C16.5613 13.9815 16.0065 13.3805 16.0065 12.6472V7.87035H17.9835L18.5382 12.4741ZM13.9892 12.6472C13.9892 13.3805 13.4344 13.9815 12.688 13.9815C12.3451 13.9815 12.0324 13.8287 11.7903 13.5639C11.5381 13.2685 11.4171 12.8713 11.4575 12.4741L12.0122 7.87035H13.9892V12.6472ZM21.0498 22.1296H8.94589C8.39113 22.1296 7.93723 21.6713 7.93723 21.1111V15.9879C8.01793 15.9981 8.08853 16.0185 8.16923 16.0185C9.04676 16.0185 9.8436 15.6518 10.4286 15.0509C11.0338 15.662 11.8407 16.0185 12.7586 16.0185C13.6362 16.0185 14.4229 15.6518 15.0079 15.0713C15.603 15.6518 16.41 16.0185 17.3178 16.0185C18.165 16.0185 18.972 15.662 19.5771 15.0509C20.1622 15.6518 20.959 16.0185 21.8365 16.0185C21.9172 16.0185 21.9878 15.9981 22.0685 15.9879V21.1111C22.0584 21.6713 21.6045 22.1296 21.0498 22.1296Z" fill="#262626"/>
                <path d="M15 17.2344C11.7946 17.2344 11.6793 22.1824 11.6793 22.1824H18.2053C18.2053 22.1824 18.2053 17.2344 15 17.2344Z" fill="#262626"/>
            </g>
            <defs>
                <clipPath id="clip0_1874_3702">
                    <rect width="22" height="22" fill="white" transform="translate(4 4)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default StorefrontIcon
