import React, { Component } from 'react'
import { formatPhoneNumber } from 'phone-numbers'
import ConfirmModal from 'confirm-modal'
import LiveReceptionistModal from 'live-receptionist-modal'
import Dialog from 'dialog-mui'
import { DialogContent, DialogActions } from '@material-ui/core'
import { NumberTypes, NumberTypeOption, OnCallOption, ScheduleOption, areRulesUnsupported, checkHasChange, liveReceptionistPresent, intelligentAnswerBotPresent, hubspotFormConfig, isScriptOfTypePresent } from './configure_util'
import Api from 'api'
import LoaderFull from 'loader-full'
import Prompt from 'pdc-prompt'
import gtmDataPush from 'gtm-events'
import { theme } from 'get-theme'
import { Checkbox } from 'checkbox-mui'
import { Button } from 'button-mui'
import { ArrowForwardIosIcon } from 'svg-icons'

import { withStyles } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import styles from './styles'
import { getCurrentNumberRule, RulesProps } from './current_rules'
import SaveNumberConfig from './save'

import { getValue } from 'remote-config-value'
import SchedulesRules from './SchedulesRules'
import UsersDropdown from './UsersDropdown'

import { Switch } from 'switch'
import Typography from 'typography'
import { ArrowBackIosIcon } from 'svg-icons/src/navigation'
import CallHandlingIcon from './icons/CallHandlingIcon'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import Section from './Section'
import { validate, ValidationErrors } from './validate'
import { Alert, Color as AlertColor } from 'alert-mui'
import { Teammate } from 'models'
import BottomNavigation from 'bottom-navigation'
import ProgressBar from 'progress-bar-mui'
import RemoteConfigValue from 'remote-config-value'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import { EditIcon } from 'svg-icons'
import IABPromoModal from './IABPromoModal'
import Divider from '@material-ui/core/Divider'
import { getFeatureEnabled } from 'feature-flag'

import { isScriptPresentOnOtherNumbers, isScriptPresentOnOtherSchedule } from './configure_util'

const GtmAppName = 'configure-number'
const GtmMap = { OtherOption: 1, SameOption: 0 }

/***/
export interface Props {
    phoneNumber: any
    schedules: any[]
    companyExtension: any
    onClose?: () => void
    setBusy: (busy: boolean) => void
    users: Teammate[]
    mainCompanyNumberVoipDidId: number | null
    screenViewType: any
    updateMainCompanyNumberVoipDidId: (voipDidId: number) => void
    updateNumberRules: (numberData: any) => void
    updatePhoneNumber: (phoneNumber: any) => void
    updateCompanyExtensionRules: (rules: any[]) => void
    isOffline?: boolean
    companyExtensionRules: any
    companyName?: string
    /**
     * In case it is company-setup then only some parts will be shown
     */
    origin: 'number-chr' | 'company-setup'
    classes
    editingData?
    onUpdate?: (states: any) => void
    setLoading?: (isLoading: boolean) => void
    /**
     * When switched from false to true it will validate the form and show validateion errors
     */
    save?: boolean
    setIsValid?: (isValid: boolean) => void
    onSaved?: (hadChange?: boolean) => void
    onError?: () => void
    subscribeForCHRNotifications: (f: (data) => void) => void
    areBusinessHoursSet?: boolean
    savingMessage?: string
    savingMessageDataTestId?: string
    phoneNumbers?: any[]
}

interface State extends RulesProps {
    loading: boolean
    saving: boolean
    uploadedAudio: any
    validationErrors: ValidationErrors
    showValidationErrors: boolean
    pdcPromptOpen: boolean
    errorPromptMessage: string
    pdcPromptMessage: string
    discardChangesPopupShow: boolean
    iabPromoPopupShow: boolean
    liveReceptionistChargesPopupShow: boolean
    IABChargesPopupShow: boolean
    IABChargesPopupConfirmed: boolean
    liveReceptionistChargesMessage: string
    showLiveReceptionistModal: boolean
    showCompanyBusinessHoursAlert: boolean
    saveProgress: number
    saveProgressMessages: string[]
    saveId: string
    lrModalOpen: boolean
    receptionistOption: 'virtual' | 'live' | 'live-plus'
    contactedExpert: boolean
    contactedExpertInConfirmation: boolean
    disableScriptPopupShow: boolean
    selectedPendingCallHandlingOption: string
    avoAnswerIsLive: boolean
    answerNetAnswerIsLive: boolean
}

class ConfigureCalling extends Component<Props, State> {
    textareaRef2: React.Ref<HTMLTextAreaElement>
    wrapperRef: React.Ref<HTMLDivElement>
    hasChange: boolean
    constructor (props) {
        super(props)
        const rules = getCurrentNumberRule(this.props)
        const state = {
            loading: false,
            saving: false,
            uploadedAudio: null,
            validationErrors: {},
            showValidationErrors: false,
            pdcPromptOpen: false,
            errorPromptMessage: null,
            pdcPromptMessage: null,
            showCompanyBusinessHoursAlert: true,
            saveProgress: 0,
            saveProgressMessages: [],
            saveId: null,
            lrModalOpen: false,
            existingReceptionistScripts: [],
            receptionistOption: 'virtual',
            contactedExpert: false,
            contactedExpertInConfirmation: false,
            ...rules
        }

        if (areRulesUnsupported(this.props.phoneNumber)) {
            const phoneNumber = this.props.phoneNumber.phone_number
            state.errorPromptMessage = `Advanced rules are set for number ${phoneNumber} from the control panel. Here you can only override it.`
        }

        this.state = state

        this.textareaRef2 = React.createRef()
        this.wrapperRef = React.createRef()
    }

    componentDidMount = async () => {
        if (this.props.origin === 'company-setup') this.updateParent()
        if (this.props.editingData) this.setState({ ...this.props.editingData })
        const scriptId = this.extractScriptIdFromCallRules(this.props.phoneNumber.call_rules)
        if (scriptId) this.setState({ scriptId })
        Api.listLiveAnswerScripts().then((existingReceptionistScripts) => {
            if (existingReceptionistScripts?.items?.length) this.setState({ existingReceptionistScripts: existingReceptionistScripts.items })
            if (this.isFirstTimeConfiguring() && !intelligentAnswerBotPresent(this)) {
                this.setState({ iabPromoPopupShow: true })
            }
        })

        getFeatureEnabled('avo_answer_is_live').then((enabled) => {
            this.setState({ avoAnswerIsLive: enabled })
        }, (enabled) => {
            this.setState({ avoAnswerIsLive: enabled })
        })
        getFeatureEnabled('answernet_answer_is_live').then((enabled) => {
            this.setState({ answerNetAnswerIsLive: enabled })
        }, (enabled) => {
            this.setState({ answerNetAnswerIsLive: enabled })
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        const hasChange = checkHasChange(this)
        if (this.hasChange !== hasChange) {
            this.hasChange = hasChange
            this.props.setBusy(hasChange)
        }
        if (this.state.onCallOptionGroups !== prevState.onCallOptionGroups) this.updateParent(hasChange)
        if (prevState.loading !== this.state.loading) {
            this.props.setLoading?.(this.state.loading)
        }
        if (!prevProps.save && this.props.save) {
            if (!checkHasChange(this)) {
                return this.props.onSaved?.(false)
            }
            this.onSave()
        }
        if (prevProps.isOffline !== this.props.isOffline && this.props.isOffline && this.state.saving) {
            this.setState({
                errorPromptMessage: 'Network error. Please check if the configuration is saved once you\'re online.',
                saving: false,
                saveProgress: 0,
                saveProgressMessages: [],
                saveId: null
            })
        }
    }

    extractScriptIdFromCallRules = (callRules) => {
        if (Object.keys(this.props.phoneNumber.call_rules).length === 0) return null
        for (const ruleGroup of callRules.rule_groups) {
            if (ruleGroup?.rules?.actions[0]?.type === 'live_answer' && ruleGroup?.rules?.actions[0]?.data?.id) {
                return ruleGroup.rules.actions[0].data.id
            }
        }
        return null
    }

    validate = (onlyCurrentTab = false): boolean => {
        const validationInfo = validate(this, onlyCurrentTab)
        const { selectedScheduleOption } = this.state
        let isValid = true
        console.log('validationInfo', validationInfo)
        if (!validationInfo.isValid) {
            if (!onlyCurrentTab || validationInfo.errors[selectedScheduleOption]) isValid = false
            this.setState({ showValidationErrors: true, validationErrors: validationInfo.errors }, () => this.goToErrorElement())
        } else {
            const validationErrors = onlyCurrentTab ? { ...this.state.validationErrors } : {}
            if (onlyCurrentTab) delete validationErrors[selectedScheduleOption]
            const showValidationErrors = onlyCurrentTab ? this.state.showValidationErrors : false
            this.setState({ showValidationErrors, validationErrors })
        }
        this.props.setIsValid?.(isValid)
        console.log('isValid', isValid)
        return isValid
    }

    goToErrorElement = () => {
        const errorElement = document.getElementsByClassName('error')[0]
        const muiErrorElement = document.getElementsByClassName('Mui-error')[0]
        if (errorElement) errorElement.scrollIntoView()
        else if (muiErrorElement) muiErrorElement?.scrollIntoView()
        else {
            // Find some other schedules tab with error and go to it
            const { useSchedules, validationErrors } = this.state
            if (!useSchedules) return
            const { selectedScheduleOption } = this.state
            if (validationErrors[selectedScheduleOption]) return
            for (const key in ScheduleOption) {
                if (validationErrors[ScheduleOption[key]]) {
                    this.setState({ selectedScheduleOption: ScheduleOption[key] })
                    return
                }
            }
        }
    }

    updateParent = (hasChange = false) => {
        // const states = JSON.parse(JSON.stringify(this.state.onCallOptionGroups))
        const data = {
            phoneNumber: this.props.phoneNumber,
            schedules: this.props.schedules,
            onCallOptionGroups: this.state.onCallOptionGroups,
            smsForwardUser: this.state.smsForwardUser,
            numberTypeOption: this.state.numberTypeOption,
            hasChange: hasChange
        }
        this.props.onUpdate?.(data)
    }

    handlepdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null })
    onErrorPromptClose = () => this.setState({ errorPromptMessage: null })

    renderNumberTypeSection = () => {
        const { classes, origin } = this.props
        if (origin === 'company-setup') return null
        const numberTypeOption = this.state.numberTypeOption
        const mainCompanyNumberOption = NumberTypes.find(option => option.value === NumberTypeOption.MainCompanyNumber)
        if (this.props.mainCompanyNumberVoipDidId && this.props.mainCompanyNumberVoipDidId !== this.props.phoneNumber.voip_did_id) {
            if (mainCompanyNumberOption) {
                mainCompanyNumberOption.disabled = true
                mainCompanyNumberOption.tooltipText = getValue('configure_number_main_number_already_set_tooltip')
            }
        } else {
            if (mainCompanyNumberOption) {
                delete mainCompanyNumberOption.disabled
                delete mainCompanyNumberOption.tooltipText
            }
        }
        const onChange = (event) => {
            let numberTypeOption = event.target.value
            if (!numberTypeOption) return
            let isDifferentOption = this.state.numberTypeOption !== numberTypeOption
            if (numberTypeOption === mainCompanyNumberOption.value && mainCompanyNumberOption.disabled) {
                numberTypeOption = this.state.numberTypeOption
                isDifferentOption = false
            }
            // eslint-disable-next-line
            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `number-type-option;${numberTypeOption}`, PDC_Value: isDifferentOption ? GtmMap.OtherOption : GtmMap.SameOption })
            if (!isDifferentOption) return
            // Not sure why it didn't update the text with `this.setState({ numberTypeOption })` only.
            this.setState({ numberTypeOption: '' }, () => {
                const stateUpdateObject: any = { numberTypeOption }
                if (numberTypeOption === NumberTypeOption.Other && !this.state.useSchedules) {
                    stateUpdateObject.selectedScheduleOption = ScheduleOption.OpenHours
                }
                this.setState(stateUpdateObject)
            })
        }
        const isFax = [NumberTypeOption.FaxLine].includes(this.state.numberTypeOption as NumberTypeOption)
        const showError = Boolean(this.state.showValidationErrors && this.state.numberTypeOption === '')
        return (
            <div className={`${classes.section} number-type-section ${isFax ? 'fax' : ''}`}>
                <Typography classes={{ root: 'big-title' }} remoteConfigID='configure_number_number_type_title' variant='h3'/>
                <span className='question'><Typography classes={{ root: classes.nowrap }} variant='subtitle1' remoteConfigID='configure_number_number_type_question_text'/>
                    <Select
                        key = {this.props.phoneNumber.phone_number}
                        value = {this.state.numberTypeOption}
                        onChange = {onChange}
                        error = {showError}
                        MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                        formControlClasses = {{ root: `${classes.dropdownMenu} size-lg` }}
                        data-test-id = 'number-type-menu'
                    >
                        {NumberTypes.map((numberType, i) => {
                            const menuItem = (
                                <MenuItem
                                    key = {numberType.value}
                                    value = {numberType.value}
                                    checked = {numberType.value === this.state.numberTypeOption}
                                    data-test-id = {numberType['data-test-id']}
                                    disabled = {!!numberType.disabled}
                                    classes = {{ root: classes.menuItem }}
                                >
                                    <Tooltip title={numberType.tooltipText || ''} placement='right'>
                                        <div className={classes.menuItemContentWrapper}>{numberType.content}</div>
                                    </Tooltip>
                                </MenuItem>
                            )
                            return menuItem
                        })}
                    </Select>
                    <div className='remove-section-spacings'>
                        {numberTypeOption === NumberTypeOption.FaxLine ? this.renderAssignSection() : null}
                    </div>
                </span>
                {numberTypeOption !== NumberTypeOption.FaxLine ? this.renderAssignSection() : null}
            </div>
        )
    }

    renderOnCallSection = () => {
        const { classes, schedules, screenViewType } = this.props
        const onCallOptions = Object.values(OnCallOption)
        const { selectedScheduleOption, useSchedules, showValidationErrors, validationErrors, existingReceptionistScripts } = this.state
        const onCallOptionGroups = { ...this.state.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        const periodTextId = useSchedules && !this.isOpen24Schedule(schedules)
            ? {
                [ScheduleOption.OpenHours]: 'configure_number_during_business_hours_question', // ' during business hours',
                [ScheduleOption.ClosedHours]: 'configure_number_outside_business_hours_question', // ' ouside of business hours',
                [ScheduleOption.Holidays]: 'configure_number_during_holiday_hours_question', // ' during holidays',
                [ScheduleOption.LunchBreak]: 'configure_number_during_lunch_hours_question' // ' during lunch break'
            }[schedules?.length ? this.state.selectedScheduleOption : null] || ''
            : 'configure_number_general_question'
        const hasError: boolean = showValidationErrors && validationErrors[selectedScheduleOption] && !onCallOptionGroup.onCallOption
        const selectScriptArray = [
            (existingReceptionistScripts
                .filter(value => {
                    return ((onCallOptionGroup.onCallOption === OnCallOption.VirtualAnswer && value.vendor?.id === 4) ||
                    (onCallOptionGroup.onCallOption === OnCallOption.LiveAnswer && value.vendor?.id === 1) ||
                    ((onCallOptionGroup.onCallOption === OnCallOption.LiveAnswerPlus) && value.vendor?.id === 8))
                })
                .map((value, i = 1) => {
                    let name = value.name
                    if (!value.enabled) {
                        name = `${name} (disabled)`
                    }
                    return (
                        <MenuItem
                            key = {i}
                            value = {value.id}
                            checked = {value.id === this.state.scriptId}
                            data-test-id = {value.id}
                        >{name}</MenuItem>
                    )
                }))
        ].flat()
        const createNewScriptOption = (
            <MenuItem
                key = {0}
                value = {-1}
                checked = {this.state.scriptId === -1}
                data-test-id = {'create-new-script'}
            >Create new script</MenuItem>)
        if (onCallOptionGroup.onCallOption !== OnCallOption.LiveAnswer || this.state.avoAnswerIsLive) {
            // add create new script option to start of the array
            selectScriptArray.unshift(createNewScriptOption)
        }
        console.log('selectScriptArray', selectScriptArray)
        return (
            <Section screenViewType={screenViewType} classes={classes} icon={<CallHandlingIcon/>} remoteConfigID='configure_number_call_handling_header_text' data-test-id='call-type-section'>
                <Typography classes={{ root: 'question' }} variant='subtitle1'>
                    <RemoteConfigValue valueId={periodTextId}/>
                    <RemoteInfoTipMui
                        arrow
                        remoteConfigIDs={
                            [
                                'configure_numbers_edit_number_call_handling_chr_options_ring_users',
                                'configure_numbers_edit_number_call_handling_chr_options_use_menu',
                                'configure_numbers_edit_number_call_handling_chr_options_send_voicemail',
                                'configure_numbers_edit_number_call_handling_chr_options_forward_number',
                                'configure_numbers_edit_number_call_handling_chr_options_receptionist',
                                'configure_numbers_edit_number_call_handling_chr_options_intelligent_answer'
                            ]
                        }
                        placement={RemoteInfoTipMuiPlacement.TOP}
                    />
                </Typography>
                <div className={`${classes.callOptionSelect} ${screenViewType.isMobileView ? 'mobile' : ''}`}>
                    <Select
                        value = {onCallOptionGroup.onCallOption}
                        onChange = {(event) => {
                            const onCallOption: OnCallOption = event.target.value as OnCallOption
                            // if call option is changed from live or virtual show a popup
                            let scriptInUse = false
                            if (this.props.phoneNumbers) {
                                scriptInUse = isScriptPresentOnOtherNumbers(this.state.scriptId, this.props.phoneNumber, this.props.phoneNumbers.items, selectedScheduleOption, onCallOptionGroups) || isScriptPresentOnOtherSchedule(onCallOptionGroups, selectedScheduleOption, this.props.schedules)
                            }
                            if (onCallOptionGroup.onCallOption !== onCallOption && (onCallOptionGroup.onCallOption === OnCallOption.LiveAnswer || onCallOptionGroup.onCallOption === OnCallOption.VirtualAnswer || onCallOptionGroup.onCallOption === OnCallOption.LiveAnswerPlus) && !scriptInUse) {
                                this.setState({ disableScriptPopupShow: true, selectedPendingCallHandlingOption: onCallOption })
                            } else {
                                // eslint-disable-next-line
                                gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `call-option;${onCallOption}`, PDC_Value: onCallOptionGroup.onCallOption !== onCallOption ? GtmMap.OtherOption : GtmMap.SameOption })
                                onCallOptionGroup.onCallOption = onCallOption
                                onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                                this.setState({ onCallOptionGroups })
                                const receptionistTypes = { 'use-live-receptionist': 'live', 'use-intelligent-answer-bot': 'virtual', 'use-live-receptionist-plus': 'live-plus' }
                                console.log(receptionistTypes[onCallOption])
                                this.setState({ receptionistOption: receptionistTypes[onCallOption] })
                            }
                        }}
                        error = {hasError}
                        helperText = {hasError ? 'Required field' : ''}
                        formControlClasses = {{ root: `${classes.dropdownMenu} size-lg-dynamic` }}
                        MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                        data-test-id = 'on-call-select'
                    >
                        {onCallOptions
                            .filter(value => {
                                return !(this.props.origin === 'company-setup' && (value === OnCallOption.LiveAnswer || value === OnCallOption.LiveAnswerPlus))
                            })
                            // filter out live receptionist plus if it is not enabled
                            .filter(value => {
                                return !(value === OnCallOption.LiveAnswerPlus && !this.state.answerNetAnswerIsLive)
                            })
                            // filter out live receptionist if it is not enabled and no scripts of that type are present
                            .filter(value => {
                                return !(((value === OnCallOption.LiveAnswer && !this.state.avoAnswerIsLive) || (isScriptOfTypePresent([19074, 19075, 19076, 19077, 19078, 19079, 19080], existingReceptionistScripts))))
                            })
                            .map((value, i) => {
                                let text = value.split('-').join(' ')
                                if (value === OnCallOption.LiveAnswer) {
                                    text = text.split(' ').map(word => `${word[0].toUpperCase()}${word.substring(1)}`).join(' ')
                                }
                                if (value === OnCallOption.VirtualAnswer) {
                                    text = getValue('configure_number_intelligent_answer_bot_option_text')
                                }
                                if (value === OnCallOption.LiveAnswerPlus) {
                                    text = getValue('configure_number_live_receptionist_plus_option_text')
                                }
                                return (
                                    <MenuItem
                                        key = {i}
                                        value = {value}
                                        checked = {value === onCallOptionGroup.onCallOption}
                                        data-test-id = {value}
                                    >{`${text[0].toUpperCase()}${text.substring(1)}`}</MenuItem>
                                )
                            })}
                    </Select>
                    {(existingReceptionistScripts && (onCallOptionGroup.onCallOption === OnCallOption.LiveAnswer || onCallOptionGroup.onCallOption === OnCallOption.VirtualAnswer || onCallOptionGroup.onCallOption === OnCallOption.LiveAnswerPlus) && this.props.origin !== 'company-setup') && <Select
                        value = {this.state.scriptId}
                        onChange = {this.onScriptValueChange}
                        error = {hasError}
                        helperText = {hasError ? 'Required field' : ''}
                        formControlClasses = {{ root: `${classes.dropdownMenu} size-lg-dynamic` }}
                        MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                        data-test-id = 'script-select'
                        label = 'Receptionist Script'
                    >
                        {
                            selectScriptArray
                        }
                    </Select>}
                    {this.renderLREditButton(onCallOptionGroup.onCallOption)}
                    {this.renderLRModal()}
                </div>
            </Section>
        )
    }

    onScriptValueChange = (event) => {
        const { value } = event.target
        if (value === -1) {
            return this.onCreateNewScriptChecked()
        }
        this.setState({ scriptId: value })
    }

    onCreateNewScriptChecked = () => {
        this.setState({ scriptId: -1 })
        this.setState({ showLiveReceptionistModal: true })
    }

    renderLREditButton = (opt) => {
        if (!['use-live-receptionist', 'use-intelligent-answer-bot', 'use-live-receptionist-plus'].includes(opt)) return
        const { classes } = this.props

        return (
            <span className={classes.editNumberColumn} onClick = {() => this.setState({ showLiveReceptionistModal: true })}>
                <span className='edit-button'><EditIcon/></span>
            </span>
        )
    }

    renderLRModal = () => {
        let vendorId
        if (this.state.receptionistOption === 'live') {
            vendorId = 1
        } else if (this.state.receptionistOption === 'live-plus') {
            vendorId = 8
        } else {
            vendorId = 4
        }
        const onClose = () => {
            // eslint-disable-next-line
            this.setState({ showLiveReceptionistModal: false })
        }
        const postSaveCb = (data) => {
            if (data && data.id) {
                this.setState({
                    existingReceptionistScripts: this.state.existingReceptionistScripts.filter((x) => x.id !== data.id).concat([data])
                })
                this.setState({ scriptId: data.id })
            }
        }
        return (
            <LiveReceptionistModal
                isShown = {Boolean(this.state.showLiveReceptionistModal)}
                id={this.state.scriptId}
                vendorId={vendorId}
                onClose = {onClose}
                postSaveCb={postSaveCb}
                size = { this.props.screenViewType.isMobileView ? 'sizeMobile' : 'size800'}
            />
        )
    }

    resetRulesState = () => {
        const rules = getCurrentNumberRule(this.props)
        const state = { ...rules }
        this.setState({
            ...state,
            validationErrors: {},
            showValidationErrors: false
        })
    }

    renderDiscardChangesModal = () => {
        const onReject = () => {
            // eslint-disable-next-line
            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'discard-changes;reject' })
            this.setState({ discardChangesPopupShow: false })
        }
        const onConfirm = () => {
            // eslint-disable-next-line
            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'discard-changes;confirm' })
            this.props.setBusy(false)
            this.props.onClose()
        }
        return (
            <ConfirmModal
                title = 'Discard changes?'
                isShown = {Boolean(this.state.discardChangesPopupShow)}
                content = ''
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {onReject}
                onConfirm = {onConfirm}
                size = 'size2'
            />
        )
    }

    renderDisableScriptModal = () => {
        const onReject = () => {
            // eslint-disable-next-line
            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'disable-scipt;reject' })
            this.setState({ disableScriptPopupShow: false })
        }
        const onConfirm = () => {
            // eslint-disable-next-line
            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'disable-scipt;confirm' })

            const onCallOptionGroups = { ...this.state.onCallOptionGroups }
            const onCallOptionGroup = { ...onCallOptionGroups[this.state.selectedScheduleOption] }

            // eslint-disable-next-line @typescript-eslint/naming-convention
            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `call-option;${this.state.selectedPendingCallHandlingOption}`, PDC_Value: onCallOptionGroup.onCallOption !== this.state.selectedPendingCallHandlingOption ? GtmMap.OtherOption : GtmMap.SameOption })
            onCallOptionGroup.onCallOption = this.state.selectedPendingCallHandlingOption
            onCallOptionGroups[this.state.selectedScheduleOption] = onCallOptionGroup
            this.setState({ onCallOptionGroups })
            const receptionistTypes = { 'use-live-receptionist': 'live', 'use-intelligent-answer-bot': 'virtual', 'use-live-receptionist-plus': 'live-plus' }
            console.log(receptionistTypes[this.state.selectedPendingCallHandlingOption])
            this.setState({ receptionistOption: receptionistTypes[this.state.selectedPendingCallHandlingOption] })

            this.setState({ disableScriptPopupShow: false })
        }
        return (
            <ConfirmModal
                title = { getValue('configure_number_disable_script_title') }
                isShown = {Boolean(this.state.disableScriptPopupShow)}
                content = { getValue('configure_number_disable_script_content') }
                noButtonText = 'Cancel'
                yesButtonText = 'Yes'
                onReject = {onReject}
                onConfirm = {onConfirm}
                size = 'size550'
            />
        )
    }

    renderIABPromoModal = () => {
        // focus on confirm button
        if (this.iabPromoConfirmButtonRef.current) {
            this.iabPromoConfirmButtonRef.current.focus()
        }
        const number = this.props.phoneNumber.phone_number
        const onReject = () => {
            localStorage.setItem(`numberConfigured${number}`, 'true')
            this.setState({ iabPromoPopupShow: false })
        }
        const onConfirm = () => {
            localStorage.setItem(`numberConfigured${number}`, 'true')
            const onCallOptionGroups = { ...this.state.onCallOptionGroups }
            if (this.state.useSchedules) {
                for (const key in ScheduleOption) {
                    const onCallOptionGroup = { ...onCallOptionGroups[ScheduleOption[key]] }
                    onCallOptionGroup.onCallOption = OnCallOption.VirtualAnswer
                    onCallOptionGroups[ScheduleOption[key]] = onCallOptionGroup
                }
            } else {
                const onCallOptionGroup = { ...onCallOptionGroups[this.state.selectedScheduleOption] }
                onCallOptionGroup.onCallOption = OnCallOption.VirtualAnswer
                onCallOptionGroups[this.state.selectedScheduleOption] = onCallOptionGroup
            }
            this.setState({ iabPromoPopupShow: false, onCallOptionGroups })
        }
        const content = (
            <ul>
                <li>Answer the calls you want</li>
                <li>Screen unwanted robocalls and spam</li>
                <li>Forward callers to any person you want answering the call</li>
                <a style={{ color: theme.palette.primary.main }} href=' https://www.phone.com/intelligent-answer-bot/' rel='noreferrer' target='_blank'>Learn more</a>
            </ul>
        )

        return (
            <ConfirmModal
                title = 'DON’T MISS A CALL - GET OUR NEW AI-CONNECT NOW'
                isShown = {Boolean(this.state.iabPromoPopupShow)}
                content = {content}
                noButtonText = 'No, Thank You'
                yesButtonText = 'YES - ADD for only $19.99/month.'
                onReject = {onReject}
                onConfirm = {onConfirm}
                size = 'size600'
                confirmRef={this.iabPromoConfirmButtonRef}
            />
        )
    }

    renderConfirmChargesModal = (classes) => {
        const saveNumberConfig = new SaveNumberConfig(this)
        const infotip = <RemoteInfoTipMui arrow remoteConfigIDs={['configure_number_live_reception_confirm_charges']} placement={RemoteInfoTipMuiPlacement.TOP} />
        const onReject = async () => {
            const smsForwardUser = this.state.smsForwardUser
            this.setState({ contactedExpertInConfirmation: false, liveReceptionistChargesPopupShow: false })
            this.resetRulesState()
            try {
                await Api.submitHubspotForm(hubspotFormConfig.saveScriptCancel)
            } catch (e) {
                // TODO: rollbar error
                console.error(e)
            }
            if (smsForwardUser) {
                this.setState({ smsForwardUser: smsForwardUser })
            }
        }
        const onConfirm = async () => {
            try {
                await Api.submitHubspotForm(hubspotFormConfig.saveScriptSave)
            } catch (e) {
                // TODO: rollbar error
                console.error(e)
                this.setState({ liveReceptionistChargesPopupShow: false, errorPromptMessage: 'Error saving script. Please contact support.' })
                return
            }
            saveNumberConfig.apply()
        }
        const contactExpert = async () => {
            this.setState({ contactedExpertInConfirmation: true })
            try {
                await Api.submitHubspotForm(hubspotFormConfig.saveScriptHelp)
            } catch (e) {
                // TODO: rollbar error
                console.error(e)
            }
        }

        const chargesText = (<div>
            <p className={classes.chargesSection}>
                <span className={classes.chargesText}>Live receptionist &ndash; monthly fee {infotip}</span>
                <span style={{ marginLeft: 'auto' }}>{'$90'}</span>
            </p>
            <p>Your script will be ready for use in your call handling rules. As soon as you apply the script to the call handling rules for any phone number, your live receptionist will begin answering calls.</p>
            <p><b>Are you sure you want to enable this script and begin incurring charges for your Receptionist service?</b></p>
            <p>If your account is set up for annual billing, you will be invoiced for the prorated amount for this month and the number of months remaining in your billing cycle.</p>
            <p className={classes.expertContactTextModal} onClick={contactExpert}><b>{'I\'d like to have an expert call me and tell me more'}</b></p>
        </div>)

        const thankYouText = (<div>
            <Typography>Thank you! We will be in touch with you shortly.</Typography>
        </div>)

        return (
            !this.state.contactedExpertInConfirmation
                ? <ConfirmModal
                    isShown={Boolean(this.state.liveReceptionistChargesPopupShow)}
                    title={'Confirm additional charges'}
                    content={chargesText}
                    noButtonText='Cancel'
                    yesButtonText='Save & Continue'
                    onReject={onReject}
                    onConfirm={onConfirm}
                    size='size550'
                />
                : <Dialog
                    open={Boolean(this.state.liveReceptionistChargesPopupShow)}
                    onClose={onReject}
                    showCloseButton
                >
                    <DialogContent
                        className={classes.thankYouDialog}
                    >
                        <img src={theme.icons.smallLogo} className={classes.thankYouLogo}/>
                        {thankYouText}
                    </DialogContent>
                </Dialog>
        )
    }

    renderConfirmIABChargesModal = (classes) => {
        const saveNumberConfig = new SaveNumberConfig(this)
        const onReject = async () => {
            const smsForwardUser = this.state.smsForwardUser
            this.setState({ IABChargesPopupShow: false })
            this.resetRulesState()
            if (smsForwardUser) {
                this.setState({ smsForwardUser: smsForwardUser })
            }
        }
        const onConfirm = async () => {
            this.setState({ IABChargesPopupConfirmed: false })
            this.setState({ IABChargesPopupShow: false })
            if (this.state.IABChargesPopupConfirmed) saveNumberConfig.apply()
        }

        const promotionPeriodInMonths = Number.parseInt(getValue('IAB_promotion_period_in_months'))
        const onCheckboxChange = () => {
            this.setState({ IABChargesPopupConfirmed: !this.state.IABChargesPopupConfirmed })
        }
        const now = new Date()
        now.setMonth(now.getMonth() + promotionPeriodInMonths)
        const promotionEndDateFormatted = now.toLocaleString('en-US', {
            day: 'numeric',
            month: 'long'
        })
        return (
            <Dialog
                open={Boolean(this.state.IABChargesPopupShow)}
                onClose={onReject}
                showCloseButton
                classes = {{ paper: classes.IABConfirmChargesPopup }}
            >
                <DialogContent className={classes.IABConfirmChargesPopupContent}>
                    <div className={classes.IABConfirmChargesPopupTitle}>Order details</div>
                    <div className="info-table">
                        <div className="info-table-row">
                            <Typography variant='subtitle2'>AI-Connect</Typography>
                            <Typography variant='subtitle2'>$19.99</Typography>
                        </div>
                        {promotionPeriodInMonths
                            ? (<div className="free-period">
                                <Typography variant='subtitle2'>{promotionPeriodInMonths} month free</Typography>
                                <Typography variant='caption'>Discount ends {promotionEndDateFormatted}</Typography>
                            </div>)
                            : ''}
                        <Divider style={{ margin: '15px 0 10px 0' }}></Divider>
                        <div className="info-table-row">
                            <Typography variant='subtitle2'>Amount due</Typography>
                            <Typography variant='subtitle2'>{promotionPeriodInMonths ? '0' : '$19.99'}</Typography>
                        </div>
                    </div>
                    <Checkbox
                        checked={this.state.IABChargesPopupConfirmed}
                        className={classes.IABConfirmChargesPopupCheckbox}
                        onChange={onCheckboxChange}
                        name='confirm-IAB-checkbox'
                        data-test-id='checkbox-IAB-confirm-charges'
                        labelDataTestId='checkbox-IAB-confirm-charges'
                        label={<Typography variant="body2" remoteConfigID='IAB_free_period_fee_label'/>}
                    />
                </DialogContent>
                <DialogActions className={classes.IABConfirmChargesPopupActions}>
                    <Button size='large' style={{ marginRight: '20px' }} color='secondary' onClick={onReject} data-test-id="cancel-submit-confirm-charges-popup">Cancel</Button>
                    <Button
                        size='large'
                        endIcon={<ArrowForwardIosIcon/>}
                        color='primary'
                        disabled={!this.state.IABChargesPopupConfirmed}
                        onClick={onConfirm}
                        data-test-id="submit-confirm-charges-popup"
                    >Submit</Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderLoader = () => {
        const { classes, origin } = this.props
        if (origin === 'company-setup') return null
        return <div className={classes.loadingDiv}><LoaderFull text='Please wait...' color={theme.palette.secondary[0]} styles={{ loaderFull: { left: this.props.screenViewType.isMobileView ? '50%' : 'calc(50% + 120px)' } }} size='bigger'/></div>
    }

    onGoBackClick = () => {
        const hasChange = checkHasChange(this)
        if (!hasChange) this.props.onClose()
        else this.setState({ discardChangesPopupShow: true })
    }

    renderHeaderBar = () => {
        const { classes } = this.props
        const phoneNumber = formatPhoneNumber(this.props.phoneNumber.phone_number)
        return (
            <div className={classes.headerBar}>
                <div className='go-back' onClick={this.onGoBackClick}><ArrowBackIosIcon/><Typography variant='buttonLarge'>back</Typography></div>
                <Typography variant='body2'>{phoneNumber}</Typography>
            </div>
        )
    }

    isOpen24Schedule = schedules => {
        const openHoursSchedule = schedules?.find(s => s.name === 'open-hours')
        const items = openHoursSchedule?.items
        const hasCustomItem = items?.some(item => item.start_hour !== 0 || item.start_minute !== 0 || item.end_hour !== 0 || item.end_minute !== 0)
        return !hasCustomItem
    }

    switchScheduleTab = (selectedScheduleOption) => {
        if (!this.validate(true)) return
        this.setState({ selectedScheduleOption })
    }

    collapseLRModal = () => {
        this.setState({ lrModalOpen: false })
    }

    renderMainCompanyNumberOptions = () => {
        const { schedules } = this.props
        // const storedRules = getCurrentNumberRule(this.props)
        // const existingLRNotes = storedRules?.liveAnswerConfiguration?.callHandlingNotes
        // const existingLRPlanId = storedRules?.liveAnswerConfiguration?.planId
        return (
            // scriptId = {this.state.scriptId}
            // liveAnswerConfiguration = {this.state.liveAnswerConfiguration}
            // updateLiveAnswerConfiguration = {liveAnswerConfiguration => this.setState({ liveAnswerConfiguration })}
            // collapseLRModal = {this.collapseLRModal}
            // lrModalOpen = {this.state.lrModalOpen}
            // existingLRNotes = {existingLRNotes}
            // existingLRPlanId = {existingLRPlanId}
            // receptionistOption = {this.state.receptionistOption}
            // updateReceptionistOption = {receptionistOption => this.setState({ receptionistOption })}
            <SchedulesRules
                useSchedules
                schedules = {schedules}
                isOpen24Schedule = {this.isOpen24Schedule}
                onCallOptionGroups = {this.state.onCallOptionGroups}
                selectedScheduleOption = {this.state.selectedScheduleOption}
                switchSelectedScheduleOption = {this.switchScheduleTab}
                showValidationErrors = {this.state.showValidationErrors}
                validationErrors = {this.state.validationErrors}
                renderOnCallSection = {this.renderOnCallSection}
                updateOnCallOptionGroups = {onCallOptionGroups => this.setState({ onCallOptionGroups })}
                origin = {this.props.origin}
                screenViewType = {this.props.screenViewType}
                companyExtension = {this.props.companyExtension}
                users = {this.props.users}
                phoneNumber = {this.props.phoneNumber}
                contactedExpert = {this.state.contactedExpert}
                setContactedExpert = {contactedExpert => this.setState({ contactedExpert })}
            />
        )
    }

    renderAssignSection = (showCompany = true) => {
        const { classes, users } = this.props
        const isFax = [NumberTypeOption.FaxLine].includes(this.state.numberTypeOption as NumberTypeOption)
        const showDirectUserText = [NumberTypeOption.UserNumber].includes(this.state.numberTypeOption as NumberTypeOption)
        let question: JSX.Element | string = isFax ? 'assigned to' : 'Assign text messages to:'
        if (showDirectUserText) question = <>Assign to user:</>
        const questionElement = (
            <span className='question'><Typography classes={{ root: classes.nowrap }} variant='subtitle1'>{question}</Typography>
                <UsersDropdown
                    users = {users}
                    selectedValue = {this.state.smsForwardUser}
                    onChange = {event => {
                        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'smsForwardUser;user-click' }) // eslint-disable-line
                        this.setState({ smsForwardUser: event.target.value })
                    }}
                    companyExtension = {showCompany ? this.props.companyExtension : null}
                    dataTestId = 'assign-user-select'
                    showValidationErrors = {this.state.showValidationErrors && this.state.validationErrors.assignTo}
                />
            </span>
        )
        return <div style={{ marginLeft: isFax ? 10 : 0 }}>{questionElement}</div>
    }

    renderUserNumberOptions = () => {
        return (
            <div>
                {this.renderAssignSection(false)}
            </div>
        )
    }

    renderOtherTypeNumberContent = () => {
        const { classes, schedules } = this.props
        const { useSchedules } = this.state
        // schedules[0] is the open-hours schedule
        const showToggle = schedules?.length && !this.isOpen24Schedule(schedules)
        // const storedRules = getCurrentNumberRule(this.props)
        // const existingLRNotes = storedRules?.liveAnswerConfiguration?.callHandlingNotes
        // const existingLRPlanId = storedRules?.liveAnswerConfiguration?.planId
        return (
            <div>
                {showToggle
                    ? <div className={classes.voiceLineToggleSection}>
                        <Switch
                            checked = {useSchedules}
                            onChange = {() => this.setState({ useSchedules: !useSchedules, selectedScheduleOption: ScheduleOption.OpenHours })}
                            name = 'voice-line-use-schedules'
                            value = 'voice-line-use-schedules'
                            label = {getValue('configure_number_use_company_bussiness_hours_toggle_text')}
                        />
                    </div>
                    : null
                }
                {/* scriptId = {this.state.scriptId} // if you delete this, delete <LiveReceptionistSection /> component
                    updateLiveAnswerConfiguration = {liveAnswerConfiguration => this.setState({ liveAnswerConfiguration })}
                    liveAnswerConfiguration = {this.state.liveAnswerConfiguration}
                    lrModalOpen = {this.state.lrModalOpen}
                    collapseLRModal = {this.collapseLRModal}
                    existingLRNotes = {existingLRNotes}
                    existingLRPlanId = {existingLRPlanId}
                    receptionistOption = {this.state.receptionistOption}
                    updateReceptionistOption = {receptionistOption => this.setState({ receptionistOption })} */}
                <SchedulesRules
                    useSchedules = {useSchedules}
                    schedules = {schedules}
                    isOpen24Schedule = {this.isOpen24Schedule}
                    onCallOptionGroups = {this.state.onCallOptionGroups}
                    selectedScheduleOption = {this.state.selectedScheduleOption}
                    switchSelectedScheduleOption = {this.switchScheduleTab}
                    showValidationErrors = {this.state.showValidationErrors}
                    validationErrors = {this.state.validationErrors}
                    renderOnCallSection = {this.renderOnCallSection}
                    updateOnCallOptionGroups = {onCallOptionGroups => this.setState({ onCallOptionGroups })}
                    origin = {this.props.origin}
                    screenViewType = {this.props.screenViewType}
                    companyExtension = {this.props.companyExtension}
                    users = {this.props.users}
                    contactedExpert = {this.state.contactedExpert}
                    setContactedExpert = {contactedExpert => this.setState({ contactedExpert })}
                />
            </div>
        )
    }

    checkLiveReceptionistBillingUpdate = () => {
        if (liveReceptionistPresent(this) && this.state.scriptId === -1) {
            return true
        }
        return false
    }

    checkInteligentAnswerBotBillingUpdate = () => {
        if (intelligentAnswerBotPresent(this) && this.state.scriptId === -1) {
            return true
        }
        return false
    }

    isFirstTimeConfiguring = () => {
        const number = this.props.phoneNumber.phone_number
        const isNumberConfigured = localStorage.getItem(`numberConfigured${number}`) || false
        if (!isNumberConfigured) {
            localStorage.setItem(`numberConfigured${number}`, 'true')
        }
        return !isNumberConfigured
    }

    onSave = () => {
        if (!this.validate()) return
        if (this.checkLiveReceptionistBillingUpdate() && this.state.receptionistOption === 'live') {
            this.setState({ liveReceptionistChargesPopupShow: true })
        } else if (this.checkInteligentAnswerBotBillingUpdate() && this.state.receptionistOption === 'virtual') {
            this.setState({ IABChargesPopupShow: true })
        } else {
            const saveNumberConfig = new SaveNumberConfig(this)
            saveNumberConfig.apply()
        }
    }

    renderFooter = () => {
        const { screenViewType } = this.props
        const hasChange = checkHasChange(this)
        return (
            <BottomNavigation
                nextDisabled = {!hasChange}
                noIconInNextButton
                noIconInBackButton
                nextButtonText = 'Save'
                description = {getValue('configure_number_bottom_navigation_text')}
                onBackClick = {this.onGoBackClick}
                onNextClick = {this.onSave}
                smallView = {screenViewType.isMobileView}
                className = 'bottom-navigation'
            />
        )
    }

    renderCompanyBusinessHoursAlert = () => {
        if (this.props.origin === 'company-setup') return
        if (!this.state.showCompanyBusinessHoursAlert) return null
        if (this.state.numberTypeOption === NumberTypeOption.FaxLine) return null
        const { classes } = this.props
        const content = <>With company business hours, you can modify your preferences for handling calls based on a schedule. If you want to define or modify your business hours go to Company, then select Business hours or <a style={{ color: theme.palette.primary.main }} href='https://configure.phone.com/company/business-hours' rel='noreferrer' target='_blank'>click here</a>.</>
        return (
            <Alert
                soft
                classes = {{ root: classes.companyBusinessHoursAlert }}
                content = {content}
                color = {AlertColor.INFO}
                onClose = {() => this.setState({ showCompanyBusinessHoursAlert: false })}
            />
        )
    }

    renderSavingProgress = () => {
        const { classes, savingMessageDataTestId } = this.props
        const { saveProgress/* , saveProgressMessages */, errorPromptMessage } = this.state
        const savingMessage = this.props.savingMessage || 'Please wait while we\'re saving your call handling rules'
        const savingMessageDataTestIdProps = savingMessageDataTestId ? { 'data-test-id': savingMessageDataTestId } : {}
        return (
            <div className={classes.saveProgressContainer}>
                <div className='save-progress-wrapper'>
                    <Typography variant='body1' {...savingMessageDataTestIdProps}>{savingMessage}</Typography> {/* remoteConfigID='configure_number_saving_progress_message'/> */}
                    <ProgressBar
                        color = {errorPromptMessage ? 'error' : 'primary'}
                        classes = {{ root: 'progress-bar-root', bar: 'progress-bar-fill' }}
                        value = {saveProgress}
                    />
                    {/* {saveProgressMessages.length
                        ? (
                            <div className='save-info-wrapper'>
                                {saveProgressMessages.map((message, i) => (
                                    <Typography key={i} variant='body2'><CheckIcon/> {message}</Typography>
                                ))}
                            </div>
                        )
                        : null
                    }
                    {errorPromptMessage
                        ? <div className='save-info-wrapper error'>
                            <Typography variant='body2'><AlertOutlinedIcon/> {errorPromptMessage}</Typography>
                        </div>
                        : null
                    } */}
                </div>
            </div>
        )
    }

    render = () => {
        const { classes, screenViewType, origin } = this.props
        const { saving } = this.state
        const renderOptionsFunctions = {
            [NumberTypeOption.UserNumber]: this.renderUserNumberOptions,
            [NumberTypeOption.Other]: this.renderOtherTypeNumberContent,
            [NumberTypeOption.MainCompanyNumber]: this.renderMainCompanyNumberOptions
        }
        const numberTypeOption = this.state.numberTypeOption
        const isLoading = this.state.loading || (this.props.schedules === null && numberTypeOption === NumberTypeOption.MainCompanyNumber)
        const sizeClass = screenViewType.isMobileView ? 'mobile' : screenViewType.isTabletView ? 'tablet' : ''
        const companySetupClass = origin === 'company-setup' ? 'company-setup' : ''
        const savingClass = saving ? 'saving' : ''
        return (
            <div className={`${classes.configureCallingWrapper} ${sizeClass} ${companySetupClass} ${savingClass}`} ref={this.wrapperRef}>
                {this.props.onClose ? this.renderHeaderBar() : null}
                {isLoading
                    ? this.renderLoader()
                    : saving
                        ? this.renderSavingProgress()
                        : <div className='content-wrapper'>
                            {this.renderNumberTypeSection()}
                            {origin !== 'company-setup' ? <div className='full-separator'/> : null}
                            {renderOptionsFunctions[numberTypeOption] && origin !== 'company-setup'
                                ? <Typography classes={{ root: 'big-title' }} remoteConfigID='configure_number_number_rules_title' variant='h3'/>
                                : null
                            }
                            {this.renderCompanyBusinessHoursAlert()}
                            {renderOptionsFunctions[numberTypeOption]?.()}
                            {this.renderDiscardChangesModal()}
                            {this.renderDisableScriptModal()}
                            {this.state.iabPromoPopupShow &&
                                <IABPromoModal
                                    phoneNumber={this.props.phoneNumber.phone_number}
                                    onReject={() => this.setState({ iabPromoPopupShow: false })}
                                    onConfirm={() => {
                                        const onCallOptionGroups = { ...this.state.onCallOptionGroups }
                                        if (this.state.useSchedules) {
                                            for (const key in ScheduleOption) {
                                                const onCallOptionGroup = { ...onCallOptionGroups[ScheduleOption[key]] }
                                                onCallOptionGroup.onCallOption = OnCallOption.VirtualAnswer
                                                onCallOptionGroups[ScheduleOption[key]] = onCallOptionGroup
                                            }
                                        } else {
                                            const onCallOptionGroup = { ...onCallOptionGroups[this.state.selectedScheduleOption] }
                                            onCallOptionGroup.onCallOption = OnCallOption.VirtualAnswer
                                            onCallOptionGroups[this.state.selectedScheduleOption] = onCallOptionGroup
                                        }
                                        this.setState({ onCallOptionGroups, iabPromoPopupShow: false })
                                    }}
                                />
                            }
                            {this.renderConfirmChargesModal(classes)}
                            {this.renderConfirmIABChargesModal(classes)}
                            <Prompt isOpen={this.state.pdcPromptOpen} color='tertiary' position='bottom' onClose={this.handlepdcPromptClose} content={this.state.pdcPromptMessage}/>
                            <Prompt isOpen={Boolean(this.state.errorPromptMessage)} color='important' position='top' onClose={this.onErrorPromptClose} content={this.state.errorPromptMessage}/>
                        </div>
                }
                {!isLoading && origin !== 'company-setup' ? this.renderFooter() : null}
            </div>
        )
    }
}

export default withStyles(styles)(ConfigureCalling)
