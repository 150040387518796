<template>
    <div class="accounts">
      <Submenu
        :routes="{
          'account.settings': {
            title: l.t('account-settings.system-settings', 'System settings')
          },
          'account.contacts': {
            title: l.t('app.account-information', 'Account information')
          },
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <w-loader v-if="account.loading"/>
      <template v-else>
        <w-alert v-if="account.alert" :level="account.alert.level" :message="account.alert.message" :closable="account.enable_close_alert" @closed="account.alert = null"/>
        <AccountSettingsFragment
          v-if="$route.name === 'account.settings' && account.item"
          :_account="JSON.parse(JSON.stringify(account.item))"
          @submitted="updateSettings"
          data-test-id="account-settings"
        />

        <AccountContacts
          v-if="$route.name === 'account.contacts'"
          :_account="JSON.parse(JSON.stringify(account.item))"
          @submitted="updateContacts"
          data-test-id="account-contacts"
        />
      </template>
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Account from '../../../models/Account';
  import Submenu from '../../elements/Submenu.vue';
  import AccountSettingsFragment from '../../fragments/Account.vue';
  import AccountContacts from '../../fragments/AccountContacts.vue';

  export default {
    components: {
      Submenu,
      AccountContacts,
      AccountSettingsFragment,
    },
    async created() {
      await this.$data.account.getItem();
      setTimeout(() => {
        const {hash} = window.location;
        if (hash) {
          const el = document.getElementById(hash.slice(1));
          if (el) {
            el.scrollIntoView({behavior: 'smooth', block: 'start'});
          }
        }
      }, 10);
    },
    data() {
      return {
        l,
        account: new Account(this.$session, vueComponent(this)),
      };
    },
    methods: {
      async updateSettings(account) {
        await this.$data.account.updateSettings(account);
        this.$global_emitter.$emit('session-user-updated');
        this.$global_emitter.$emit('item_updated');
      },
      updateContacts(account) {
        return this.$data.account.updateContacts(account);
      },
    },
  };
</script>
