<template>
	<w-form
		v-if="account"
		@submit.prevent="on_submit"
		@changed="may_submit = true"
		class="billing-administrative-contact mx-auto"
		data-discard="true"
	>
	<w-alert v-if="alert_message" :message="alert_message" level="error" id="address-alert-message" />
		<BillingAdministrativeContact
			:_contact="account.billing_contact"
			:countries="['US', 'CA']"
			:title="l.t('subaccounts.billing-contact', 'Billing contact')"
			:key="`billing-${l.detect()}`"
			@changed="account.billing_contact = $event"
			data-test-id="account-contacts-billing-info"
		/>
		<div class="info--text text--darken-1 w-subtitle-1 mt-10" data-test-id="acc-administrative-contact-title">
            {{ l.t('app.administrative-contact', 'Administrative contact') }}
        </div>
		<w-checkbox v-model="same_as_billing" class="mb-5">
			<template v-slot:label>
				<span class="w-body-2 text--text">
					{{l.t('account.same-as-billing-contact', 'Same as my billing contact')}}
				</span>
			</template>
		</w-checkbox>
		<transition name="collapsable">
			<BillingAdministrativeContact
				v-if="!same_as_billing"
				:_contact="account.contact"
				:key="`contact-${l.detect()}`"
				@changed="account.contact = $event"
				data-test-id="account-contacts-info"
			/>
		</transition>
		<div class="mt-10 text-center">
			<CancelChanges/>
			<w-btn
				:disabled="!may_submit || submitting"
				:loading="submitting"
				color="primary"
				class="ml-5"
				type="submit"
				data-test-id="account-contacts-submit-btn"
			>
				{{ l.t('app.save', 'Save') }}
			</w-btn>
		</div>
	</w-form>
</template>

<script>
	import {validate_address} from 'phoenix-session-helpers';
	import l from '../../libs/lang';
	import CancelChanges from '../elements/CancelChanges.vue';
	import BillingAdministrativeContact from '../elements/BillingAdministrativeContact.vue';

	export default {
		props: ['_account'],
		components: {
			CancelChanges,
			BillingAdministrativeContact,
		},
		data() {
		  return {
		    l,
			account: null,
			may_submit: false,
			same_as_billing: false,
			alert_message: null,
			submitting: false,
		  };
		},
		async created() {
			this.$data.account = this.$props._account;
			if (this.$data.account.billing_contact && this.account.contact) {
				const billing_deep_copy = JSON.parse(JSON.stringify(this.$data.account.billing_contact));
				const administrative_deep_copy = JSON.parse(JSON.stringify(this.$data.account.contact));
				delete billing_deep_copy.id;
				delete administrative_deep_copy.id;
				this.$data.same_as_billing = JSON.stringify(billing_deep_copy) === JSON.stringify(administrative_deep_copy);
			}
		},
		methods: {
			async on_submit() {
				this.$data.submitting = true;
				if (this.$data.same_as_billing) {
					const billing_deep_copy = JSON.parse(JSON.stringify(this.$data.account.billing_contact));
					billing_deep_copy.id = this.$data.account.contact.id;
					this.$data.account.contact = billing_deep_copy;
				}
				const billing_address_is_valid = await validate_address(this.$session, this.$data.account.billing_contact.address);
				let error = false;
				if (this.$data.same_as_billing) {
					error = !billing_address_is_valid.success;
					if (billing_address_is_valid.success) {
						this.$emit('submitted', this.$data.account);
					}
				} else {
					const contact_address_is_valid = await validate_address(this.$session, this.$data.account.contact.address);
					error = !contact_address_is_valid.success || !billing_address_is_valid.success;
					if (!error) {
						this.$emit('submitted', this.$data.account);
					}
				}
				if (error) {
					this.$data.alert_message = l.t('contacts.invalid-address', 'Invalid address');
					window.scrollTo(0, 0);
				}
				
				this.$data.submitting = false;
				return true;
			}
		}
	};
</script>
