var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accounts"},[_c('Submenu',{key:("" + (_vm.l.detect()) + (_vm.$route.name)),attrs:{"routes":{
      'account.settings': {
        title: _vm.l.t('account-settings.system-settings', 'System settings')
      },
      'account.contacts': {
        title: _vm.l.t('app.account-information', 'Account information')
      },
    }}}),_vm._v(" "),(_vm.account.loading)?_c('w-loader'):[(_vm.account.alert)?_c('w-alert',{attrs:{"level":_vm.account.alert.level,"message":_vm.account.alert.message,"closable":_vm.account.enable_close_alert},on:{"closed":function($event){_vm.account.alert = null}}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'account.settings' && _vm.account.item)?_c('AccountSettingsFragment',{attrs:{"_account":JSON.parse(JSON.stringify(_vm.account.item)),"data-test-id":"account-settings"},on:{"submitted":_vm.updateSettings}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'account.contacts')?_c('AccountContacts',{attrs:{"_account":JSON.parse(JSON.stringify(_vm.account.item)),"data-test-id":"account-contacts"},on:{"submitted":_vm.updateContacts}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }