<template>
  <div class="list-page extensions" data-test-id="extensions-list-page">
    <DynamicFilters>
      <DynamicFilterInput
        v-model="extension.filters.name_or_number"
        :model="extension"
        :disabled="extension.loading"
        maxlength="30"
        :validation="[$validation.maxlength(30)]"
        :placeholder="l.t('extensions.filter-by-name-or-ext-number', 'Filter extensions by name or Ext number')"
      />
    </DynamicFilters>
    <IndexPage
      :resource="extension"
      :disable_filters="true"
      :disable_delete_all="true"
    >
      <template v-slot:list>
        <v-simple-table v-if="extension.items.length" class="list-table" data-test-id="extension-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
              <th class="select-table-item" data-test-id="checkbox"><br/></th>
              <th data-test-id="extension">{{ l.t('app.extension-uppercase', 'EXTENSION') }}</th>
              <th data-test-id="description">{{ l.t('app.description-uppercase', 'DESCRIPTION') }}</th>
              <th data-test-id="device">{{ l.t('app.device-uppercase', 'DEVICE') }}</th>
              <th data-test-id="billing-type">{{ l.t('app.billing-type-uppercase', 'BILLING TYPE') }}</th>
              <th data-test-id="extension-type">{{ l.t('app.extension-type-uppercase', 'EXTENSION TYPE') }}</th>
              <th data-test-id="more-options"></th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
            <tr v-for="(item, i) in extension.items" :key="item['id']" :class="{'selected-row': item.selected}" :data-test-id="item.id">
              <template v-if="extension.deleting_item !== item.id">
                <td class="select-table-item" data-test-id="checkbox">
                  <DisabledCheckbox
                    v-if="(item.id && item.id === nxt_company_inbox_extension_id) || item.is_nxt"
                    :data-test-id="`extensions-item-${i}-checkbox-tooltip-activator`"
                  >
                    <template v-slot:label>
                      {{ 
                        item.id && item.id === nxt_company_inbox_extension_id ?
                        l.t('extensions.company-inbox-extension', 'Company inbox extension can\'t be deleted.') :
                        l.t('extensions.configure-extension', 'Cofigure extension can\'t be deleted.')
                      }}
                    </template>
                  </DisabledCheckbox>
                  <w-checkbox
                    v-else
                    v-model="item.selected"
                    @click="on_checkbox_click($event, item)"
                    :disabled="item.id && item.id === nxt_company_inbox_extension_id"
                    :key="`extensions-item-${item.id}-${item.selected}`"
                    hide-details="auto"
                    :data-test-id="`extensions-item-${i}-checkbox`"
                  />
                </td>
                <td data-test-id="link">
                  <IndexListItem
                    :route="{
                      name: 'extensions.show',
                      params: {
                        id: item['id'],
                        _extension: item,
                      },
                    }"
                    :item="item"
                    :title="`${item.extension}: ${item.name}`"
                    :key="item['id']"
                    :data-test-id="`extensions-item-${i}-title`"
                  />
                </td>
                <td data-test-id="description">
                  #{{ item["id"] }}: <span v-html="item.route_analysis.description"></span>
                </td>
                <td data-test-id="device">
                  {{item.device_membership ? item.device_membership.device ? item.device_membership.device.name : "" : ""}}
                </td>
                <td data-test-id="usage-type">
                  {{item.usage_type | capitalize}}
                </td>
                <td data-test-id="role">
                  {{get_extension_type(item)}}
                </td>
                <td data-test-id="more-options">
                  <MoreOptions :options="more_options(item)" />
                </td>
              </template>
              <td v-else colspan="4" data-test-id="loader">
                <w-loader size="small"/>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </IndexPage>
    <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="extension.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
import {nxt_company_inbox_extension} from 'phoenix-session-helpers';
import {vueComponent, consoleUserCanAccessPageMiddleware} from 'helpers';
import l from '../../../libs/lang';
import Extension from '../../../models/Extension';
import IndexPage from '../../elements/IndexPage.vue';
import MoreOptions from '../../elements/MoreOptions.vue';
import FormInput from '../../elements/form/FormInput.vue';
import IndexListItem from '../../elements/IndexListItem.vue';
import DynamicFilters from '../../elements/DynamicFilters.vue';
import DisabledCheckbox from '../../elements/DisabledCheckbox.vue';
import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';
import DynamicFilterInput from '../../elements/form/DynamicFilterInput.vue';

export default {
  components: {
    FormInput,
    IndexPage,
    MoreOptions,
    IndexListItem,
    DynamicFilters,
    ConfirmDeletion,
    DisabledCheckbox,
    DynamicFilterInput,
  },
  data() {
    return {
      l,
      deleting_item_id: null,
      extension: new Extension(this.$session, vueComponent(this)),
      nxt_company_inbox_extension_id: null,
      user_can_access_visualizer: false,
    };
  },
  async created() {
    this.$data.nxt_company_inbox_extension_id = await nxt_company_inbox_extension(this.$session);
    const route = this.$router.options.routes.find((x) => x.name === 'extensions.visualizer');
    this.$data.user_can_access_visualizer = await consoleUserCanAccessPageMiddleware(route, this.$branding, this.$session);
  },
  mounted() {
    this.$parent.$on('userRedirected', (to, from, next) => {
      this.$data.extension.handleUserRedirect(to, from, next);
    });
  },
  methods: {
    get_extension_type(value) {
        let name;
            switch (value.role) {
              case 'company-inbox': name = l.t('app.company-inbox', 'Company inbox'); break;
              case 'nxt-user': name = this.get_nxt_user_tyep(value.role_details); break;
              case 'common-area': name = l.t('app.common-area', 'Common area phone'); break;
              case 'app': name = l.t('app.application', 'Application'); break;
              case 'softphone': name = l.t('app.softphone', 'Softphone'); break;
              case 'device': name = l.t('app.device', 'Any other device'); break;
              case 'virtual': name = l.t('app.virtual-extension', 'Virtual extension'); break;
              default: name = '';
            }
            return name;
    },
    get_nxt_user_tyep(val) {
      let name;
      switch (val) {
              case 'plus': name = l.t('app.plus-user', 'Plus user'); break;
              case 'basic': name = l.t('app.basic-user', 'Basic user'); break;
              case 'pro': name = l.t('app.pro-user', 'Pro user'); break;
              default: name = l.t('app.nxt-user', 'NXT user');
            }
            return name;
    },
    on_checkbox_click(e, item) {
      this.$data.extension.checked(e, item);
    },
    more_options(item) {
      const options = [
        {
          cb: () => this.$router.push({
            name: 'extensions.show',
            params: {
              id: item['id'],
              _extension: item,
            },
          }),
          title: l.t('app.edit', 'Edit')
        },
      ];
      if (item.id && item.id !== this.$data.nxt_company_inbox_extension_id && !item.is_nxt) {
        options.push({
          cb: () => this.$data.deleting_item_id = item.id,
          title: l.t('app.delete', 'Delete'),
        });
      }
      if (this.$data.user_can_access_visualizer) {
        options.push({
          cb: () => this.$router.push({
            name: 'extensions.visualizer',
            params: {
              id: item['id'],
            },
          }),
          title: l.t('lr.delete-script', 'Visualizer'),
        });
      }

      return options;
    },
    checked(event, item) {
      this.$data.extension.checked(event, item);
      this.$emit('forceUpdate');
    }
  },
  filters: {
        capitalize(val) {
            return val.charAt(0).toUpperCase() + val.slice(1);
        },
    },
};
</script>
