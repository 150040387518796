import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import { SwipeableDrawer, ListItem, List, ListItemIcon, ListItemText, Chip, Collapse } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(styles)

function MobileDrawer (props) {
    const classes = useStyles()

    return (
        <div>
            <SwipeableDrawer
                onOpen={props.handleDrawerToggle}
                container={props.container}
                variant='temporary'
                anchor={props?.theme?.direction === 'rtl' ? 'right' : 'left'}
                open={props.mobileDrawerOpen}
                onClose={props.handleDrawerToggle}
                disableBackdropTransition={false}
                classes = {{ paper: `${classes.drawerPaper}` }}
                // Better open performance on mobile.
                ModalProps={{ keepMounted: true }}
            >
                <DrawerContent
                    renderNavHeader={props.renderNavHeader}
                    renderNavFooter={props.renderNavFooter}
                    links={props.links}
                    tabs={props.tabs}
                    handleMenuChange={props.handleMenuChange}
                    smallView
                />
            </SwipeableDrawer>
        </div>
    )
}

function DesktopDrawer (props) {
    const classes = useStyles()
    return (
        <div>
            {/* <div > */}
            <div className={classes.drawer} />
            <SwipeableDrawer
                classes = {{ paper: `${classes.drawerPaper}` }}
                variant = 'permanent'
                onOpen = {() => { /**/ }}
                onClose = {() => { /**/ }}
                open
            >
                <DrawerContent
                    renderNavHeader = {props.renderNavHeader}
                    renderNavFooter = {props.renderNavFooter}
                    links = {props.links}
                    tabs = {props.tabs}
                    handleMenuChange = {props.handleMenuChange}
                    refinedNavigationEnabled={props.refinedNavigationEnabled}
                />
            </SwipeableDrawer>
        </div>
    )
}

function DrawerContent (props) {
    const classes = useStyles()
    if (!props.tabs || !props.tabs.length) return null

    return (
        <div className={`${classes.drawableContent} ${props.smallView ? 'small-view' : ''}`}>
            {props.renderNavHeader()}
            <div className={classes.drawerWrapper}>
                <List className={classes.drawerList} disablePadding={false}>
                    {props.tabs.map(tab => {
                        if (!tab.children) {
                            return <SingleMenuItem key={tab.routeId} route={tab} handleMenuChange={props.handleMenuChange} nested={false} />
                        }
                        return <ParentMenuItem key={tab.routeId} route={tab} handleMenuChange={props.handleMenuChange} refinedNavigationEnabled={props.refinedNavigationEnabled} />
                    })}
                </List>
                <Links links={props.links} />
            </div>
            {props.renderNavFooter?.()}
        </div>
    )
}

const SingleMenuItem = (props) => {
    const route = props.route
    if (!route.enabled) return null

    const classes = useStyles(props)
    const to = route.link.indexOf('http') === -1 ? route.link : '#'
    const activeClass = route.active ? 'active' : ''
    const separateClass = route.text === 'Control Panel' ? 'separate' : ''
    const Icon = route.icon
    const unreadInfoText = route.unread > 99 ? '99+' : route.unread

    return (
        <div
            data-test-id={route.testId}
            key={route.routeId}
            onClick={route.onClick ? route.onClick : () => props.handleMenuChange(route, to)}
            className={classes.appLinkStyle}
            ref={route.ref ? route.ref : null}
        >
            <ListItem button className={`${classes.drawerItem} ${activeClass} ${separateClass}`}>
                {!props.nested &&
                    <ListItemIcon classes={{ root: classes.drawerListItemIcon }}>
                        <Icon
                            active={route.active ? 1 : 0}
                            read={!route.unread ? 1 : 0}
                            className={`${classes.drawIcon} ${route.active ? 'active' : ''} `}
                        />
                    </ListItemIcon>
                }

                <ListItemText classes={{ root: classes.navItemRoot, primary: `${classes.navItemText} ${activeClass} ${props.refinedNavigationEnabled ? classes.refinedNavItemText : ''}` }} primary={route.text} />
                {route.unread
                    ? <div className={classes.unreadInfo} data-test-id="unread-badge-div"><span title={route.unread}>{unreadInfoText}</span></div>
                    : route.badge
                        ? <Chip label={route.badge} color="secondary" size="small" />
                        : null}
                {!route.enabled && (
                    <span className={classes.disabledLabel}>
                        Coming Soon!
                    </span>
                )}
            </ListItem>
        </div>
    )
}

const ParentMenuItem = (props) => {
    const classes = useStyles()
    const parentTab = props.route

    const isParentActive = parentTab.children.some(childTab => childTab.active)
    const [open, setOpen] = useState(isParentActive)

    const doesParentHasUnreadSubMenu = parentTab.children.some(childTab => childTab.unread)
    const Icon = parentTab.icon
    const activeClass = isParentActive && !open ? 'active' : ''
    return (
        <>
            <div
                data-test-id={parentTab.testId}
                key={parentTab.routeId}
                onClick={() => setOpen(open => !open)}
                className={`${classes.appLinkStyle} do=-not-print`}
                ref={parentTab.ref ? parentTab.ref : null}
            >
                <ListItem button className={`${classes.drawerItem} ${activeClass}`}>
                    <ListItemIcon>
                        <Icon
                            active={isParentActive ? 1 : 0}
                            read={!doesParentHasUnreadSubMenu ? 1 : 0}
                            className={`${classes.drawIcon} ${activeClass} `}
                        />
                    </ListItemIcon>
                    <ListItemText classes={{ root: classes.navItemRoot, primary: `${classes.navItemText} ${activeClass}` }} primary={<>{parentTab.text}{doesParentHasUnreadSubMenu && <div className={classes.unreadInfo} data-test-id="unread-badge-div"><span></span></div>}</>} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </div>
            <Collapse classes={{ wrapper: classes.collapseWrapper }} in={open} timeout="auto" unmountOnExit style={{ minHeight: 'auto' }}>
                <List className={classes.drawerList} disablePadding={true}>
                    {
                        parentTab.children.map(route => <SingleMenuItem key={route.routeId} route={route} handleMenuChange={props.handleMenuChange} refinedNavigationEnabled={props.refinedNavigationEnabled} nested={true} />)
                    }
                </List>
            </Collapse>
        </>
    )
}

/**
 *
 * @param {Props} props
 */
function Links (props) {
    const { links } = props
    const classes = useStyles()

    const openNewTab = (address) => {
        window.open(address, '_blank')
    }

    if (!links || !links.length) return null
    return (
        <div className={`${classes.linksSection}`}>
            <List className={classes.drawerList}>
                {links.map((link) => {
                    const Icon = link.icon
                    return (
                        <div
                            data-test-id={link.testId}
                            key={link.routeId}
                            onClick={link.onClick ? link.onClick : () => openNewTab(link.address)}
                            className={classes.appLinkStyle}
                            ref={link.ref ? link.ref : null}
                        >
                            <ListItem button className={`${classes.drawerItem} ${classes.drawerLinkItem}`}>
                                <ListItemIcon className={classes.iconWrapper}>
                                    <Icon
                                        className={`${classes.drawIcon}`}
                                    />
                                </ListItemIcon>
                                <ListItemText classes={{ root: classes.navItemRoot, primary: `${classes.navItemText}` }} primary={link.text} />
                            </ListItem>
                        </div>
                    )
                })}
            </List>

        </div>
    )
}

DesktopDrawer.propTypes = {
    container: PropTypes.object,
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    renderNavHeader: PropTypes.func,
    renderNavFooter: PropTypes.func,
    tabs: PropTypes.array,
    refinedNavigationEnabled: PropTypes.bool
}

Drawer.propTypes = {
    container: PropTypes.object,
    handleDrawerToggle: PropTypes.func,
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    mobileDrawerOpen: PropTypes.bool,
    renderNavHeader: PropTypes.func,
    renderNavFooter: PropTypes.func,
    screenViewType: PropTypes.bool,
    tabs: PropTypes.array,
    refinedNavigationEnabled: PropTypes.bool
}

DrawerContent.propTypes = {
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    renderNavHeader: PropTypes.func,
    renderNavFooter: PropTypes.func,
    tabs: PropTypes.array,
    smallView: PropTypes.bool,
    refinedNavigationEnabled: PropTypes.bool
}

SingleMenuItem.propTypes = {
    route: PropTypes.object,
    handleMenuChange: PropTypes.func,
    nested: PropTypes.bool,
    refinedNavigationEnabled: PropTypes.bool
}

ParentMenuItem.propTypes = {
    route: PropTypes.object,
    handleMenuChange: PropTypes.func,
    refinedNavigationEnabled: PropTypes.bool
}

Links.propTypes = {
    links: PropTypes.array
}

MobileDrawer.propTypes = {
    container: PropTypes.object,
    handleDrawerToggle: PropTypes.func,
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    mobileDrawerOpen: PropTypes.bool,
    renderNavHeader: PropTypes.func,
    renderNavFooter: PropTypes.func,
    tabs: PropTypes.array,
    theme: PropTypes.object
}

/**
 * @param {object} props
 */
export default function Drawer (props) {
    const { isMobileView, isTabletView } = props.screenViewType
    const isSmallView = isMobileView || isTabletView

    return isSmallView
        ? <MobileDrawer
            renderNavHeader={props.renderNavHeader}
            renderNavFooter={props.renderNavFooter}
            links={props.links}
            tabs={props.tabs}
            mobileDrawerOpen={props.mobileDrawerOpen}
            container={props.container}
            handleDrawerToggle={props.handleDrawerToggle}
            handleMenuChange={props.handleMenuChange}
        />
        : <DesktopDrawer
            renderNavHeader={props.renderNavHeader}
            renderNavFooter={props.renderNavFooter}
            handleMenuChange={props.handleMenuChange}
            tabs={props.tabs}
            links={props.links}
            container={props.container}
            handleDrawerToggle={props.handleDrawerToggle}
            refinedNavigationEnabled={props.refinedNavigationEnabled}
        />
}
