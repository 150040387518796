<template>
    <w-loader v-if="billing.loading" size="small" />
    <div v-else-if="billing.invoice" class="invoice" data-test-id="invoice">
      <div id="pdf-export">
        <div class="d-flex small-details-table-wrapper">
          <iframe
            v-if="$branding.data.code === 'default' && $vuetify.breakpoint.lgAndUp"
            src="https://www.phone.com/splash-pages/statements-promo/"
            width="480px"
            height="220px"
          />
          <v-spacer v-else/>
          <div
            v-if="details_table_data"
            class="small-details-table w-body-2"
          >
            <div
              v-for="data of Object.values(details_table_data)"
            >
              <div
                class="details-row"
              >
                <v-divider v-if="data.divider"></v-divider>
                <template v-else>
                  <div>{{ data.translation }}</div>
                  <div>{{ data.value || '&mdash;'  }}</div>
                </template>
              </div>
              <div v-if="data.note" v-html="data.note" class="w-caption details-table-note"></div>
            </div>
          </div>
        </div>
        <div class="pa-4 mb-5 light-background w-body-2">
          {{ $lang.t('app.invoice', 'Invoice') }} #{{ billing.invoice.id }} | <span class="info--text darken-1--text">{{ $lang.t('billing.billing-details', 'Billing details') }}</span>
        </div>
        <div v-if="billing.invoice.payments.length" class="w-body-1 mt-3">{{ $lang.t('invoice.payments', 'Payments') }}</div>
        <v-simple-table v-if="billing.invoice.payments.length" data-test-id="invoice-payments-table">
          <thead data-test-id="table-head">
            <tr data-test-id="table-head-row">
              <th data-test-id="date">{{ $lang.t('app.date', 'Date') }}</th>
              <th data-test-id="description" style="width: 100%">{{ $lang.t('app.description', 'Description') }}</th>
              <th data-test-id="amount">{{ $lang.t('billing.ammount', 'Amount') }}</th>
            </tr>
          </thead>
          <tbody data-test-id="tabble-body">
            <tr v-for="t of billing.invoice.payments" v-bind:key="t.id" :class="{'deleted': t.status === 'deleted'}" :data-test-id="t.start_date">
              <td data-test-id="date">{{ t.start_date | format_date }}</td>
              <td data-test-id="description">{{ billing.invoice.t_description(t) }}{{ t.data ? ': ' : ''}}<strong v-if="t.data">{{ t.data }}</strong></td>
              <td data-test-id="amount" :class="{'price': true, 'charge': t.amount < 0, 'payment': t.amount > 0}">{{ format_money(positive_number(t.amount)) }}</td>
            </tr>
          </tbody>
          <tfoot data-test-id="table-footer">
            <tr class="font-weight-bold total-row" data-test-id="table-footer-row">
              <td data-test-id="total" colspan="2">{{ $lang.t('app.total', 'Total') }}</td>
              <td data-test-id="price" class="price">{{ format_money(positive_number(billing.invoice.payments.reduce((a,c) => a + c.amount, 0))) }}</td>
            </tr>
          </tfoot>
        </v-simple-table>
        <template v-if="billing.invoice.adjustments.length">
          <div class="w-body-1 mt-3">{{ $lang.t('invoice.adjustments', 'Adjustments') }}</div>
          <v-simple-table v-if="billing.invoice.adjustments.length" data-test-id="invoice-adjustments-table">
            <thead data-test-id="table-head">
              <tr data-test-id="table-head-row">
                <th data-test-id="date">{{ $lang.t('app.date', 'Date') }}</th>
                <th data-test-id="description" style="width: 100%">{{ $lang.t('app.description', 'Description') }}</th>
                <th data-test-id="amount">{{ $lang.t('billing.ammount', 'Amount') }}</th>
              </tr>
            </thead>
            <tbody data-test-id="table-body">
              <tr v-for="t of billing.invoice.adjustments" v-bind:key="t.id" :class="{'deleted': t.status === 'deleted'}" :data-test-id="t.start_date">
                <td data-test-id="date">{{ t.start_date | format_date }}</td>
                <td data-test-id="description">{{ billing.invoice.t_description(t) }}{{ t.data ? ': ' : ''}}<strong v-if="t.data">{{ t.data }}</strong></td>
                <td data-test-id="amount" :class="{'price': true, 'charge': t.amount < 0, 'payment': t.amount > 0}">{{ format_money(positive_number(t.amount)) }}</td>
              </tr>
            </tbody>
            <tfoot data-test-id="table-footer">
              <tr class="font-weight-bold total-row" data-test-id="table-footer-row">
                <td data-test-id="total" colspan="2">{{ $lang.t('app.total', 'Total') }}</td>
                <td data-test-id="price" class="price">{{ format_money(positive_number(billing.invoice.adjustments.reduce((a,c) => a + c.amount, 0))) }}</td>
              </tr>
            </tfoot>
          </v-simple-table>
        </template>
        <div v-if="billing.invoice.activity.length" class="w-body-1 mt-3">{{ $lang.t('billing.activity', 'Activity') }}</div>
        <v-simple-table v-if="billing.invoice.activity.length" data-test-id="invoice-activity-table">
          <thead data-test-id="table-head">
            <tr>
              <th data-test-id="period">{{ $lang.t('app.period', 'Period') }}</th>
              <th data-test-id="description" style="width: 100%">{{ $lang.t('app.description', 'Description') }}</th>
              <th data-test-id="cost">{{ $lang.t('billing.cost', 'Cost') }}</th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
            <tr v-for="t of billing.invoice.activity" v-bind:key="t.id" :class="{'deleted': t.status === 'deleted'}" :data-test-id="t.start_date">
              <td data-test-id="date" class="nowrap">{{ t.start_date | format_date }} - {{ t.end_date | format_date }}</td>
              <td data-test-id="description">{{ billing.invoice.t_description(t) }}{{ t.data ? ': ' : ''}}<strong v-if="t.data">{{ t.data }}</strong></td>
              <td data-test-id="amount" :class="{'price': true, 'charge': t.amount > 0, 'payment': t.amount < 0}">{{ format_money(t.amount) }}</td>
            </tr>
          </tbody>
          <tfoot data-test-id="table-footer">
            <tr class="font-weight-bold total-row" data-test-id="table-footer-row">
              <td data-test-id="total" colspan="2">{{ $lang.t('app.total', 'Total') }}</td>
              <td data-test-id="price" class="price">{{ format_money(billing.invoice.activity.reduce((a,c) => a + c.amount, 0)) }}</td>
            </tr>
          </tfoot>
        </v-simple-table>
        <div v-if="billing.invoice.taxes.length" class="w-body-1 mt-3">{{ $lang.t('billing.taxes', 'Taxes') }}</div>
        <v-simple-table v-if="billing.invoice.taxes.length" data-test-id="invoice-taxes-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
              <th data-test-id="date">{{ $lang.t('app.period', 'Period') }}</th>
              <th data-test-id="description" style="width: 100%">{{ $lang.t('app.description', 'Description') }}</th>
              <th data-test-id="amount">{{ $lang.t('billing.ammount', 'Amount') }}</th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
            <tr v-for="t of billing.invoice.taxes" v-bind:key="t.id" :class="{'deleted': t.status === 'deleted'}" :data-test-id="t.start_date">
              <td data-test-id="date">{{ t.start_date | format_date }} - {{ t.end_date | format_date }}</td>
              <td data-test-id="description">{{ t.description }}</td>
              <td data-test-id="amount" :class="{'price': true, 'charge': t.amount > 0, 'payment': t.amount < 0}">{{ format_money(t.amount) }}</td>
            </tr>
          </tbody>
          <tfoot data-test-id="table-footer">
            <tr class="font-weight-bold total-row" data-test-id="table-footer-row">
              <td colspan="2" data-test-id="total">{{ $lang.t('app.total', 'Total') }}</td>
              <td class="price" data-test-id="price">{{ format_money(billing.invoice.taxes.reduce((a,c) => a + c.amount, 0)) }}</td>
            </tr>
          </tfoot>
        </v-simple-table>
      </div>
      <CancelNote>
        <template v-slot:cancelText>
          {{$lang.t('app.to-cancel-your-account', 'To cancel your account contact our team at (877) 472-9245, or')}} <a href="https://support.phone.com/contact-us" target="_blank" class="primary--text text--darken-1">{{$lang.t('app.submit-a-service-request', 'submit a service request')}}</a>.
            </template>
      </CancelNote>
    </div>
    <w-alert v-else :message="$lang.t('app.not-found', 'Not found')" :closable="false" />
  </template>
  
  <script>
  import {formatDate, formatTime} from 'formatters';
  import CancelNote from 'console/src/components/elements/CancelNote.vue';
  
  export default {
    props: ['billing', 'invoice', 'id'],
    components: {
      CancelNote,
    },
    data() {
      return {
        details_table_data: null,
      };
    },
    async created() {
      if (this.$props.billing && this.$props.billing.invoice) {
        const prev_balance = this.get_previous_balance();
  
        const payments_raw = this.payments();
        const payments = payments_raw < 0 ? -1 * payments_raw : payments_raw;
  
        const balance = this.get_balance();
        const adjustment = this.get_adjustments();
        const new_charges = this.get_new_charges();
        const amount_due = this.get_amount_due();
  
        const details_table_data = {
          prev_balance: {
            translation: this.$lang.t('invoice.previous-ballance', 'Previous balance'),
            value: this.format_money(prev_balance),
          },
          'payments': {
            translation: this.$lang.t('invoice.payments', 'Payments'),
            value: this.format_money(payments),
            note: this.get_payment_note(),
          },
          'balance': {
            translation: this.$lang.t('invoice.balance', 'Balance'),
            value: this.format_money(balance),
          },
          'adjustments': {
            translation: this.$lang.t('invoice.adjustments', 'Adjustments'),
            value: this.format_money(adjustment),
          },
          'new_charges': {
            translation: this.$lang.t('invoice.new-charges', 'New charges'),
            value: this.format_money(new_charges),
          },
          'divider': {
            divider: true,
          },
          'amount_due': {
            translation: this.$lang.t('invoice.amount-due', 'Amount due'),
            value: this.format_money(amount_due),
          },
          'payment_due': {
            translation: this.$lang.t('invoice.payment-due-date', 'Payment due date'),
            value: this.$props.billing.invoice.data.closed_at ? `${formatDate(this.$props.billing.invoice.data.closed_at)}` : '—',
          },
        };
        if (Object.values(details_table_data).find((x) => x.value || x.value === 0)) {
          this.$data.details_table_data = details_table_data;
        }
        return true;
      }
  
      await this.$props.billing.showInvoice(
        this.$props.invoice || this.$props.id || this.$route.params.id
      );
      return true;
    },
    methods: {
      print() {
        window.print();
      },
      get_payment_note() {
        if (
          this.$props.billing.invoice.data.status === 'closed'
          && this.$props.billing.invoice.data.closed_at
        ) {
          let date = new Date(this.$props.billing.invoice.data.closed_at * 1000);
          date = date.toLocaleString('en-US', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
          });
          const last_4_cc_digits = this.billing.invoice.payments.length ? this.billing.invoice.payments[0].data : '&mdash;';
          return `${date}<br/>${this.$lang.t('invoice.cc-payment-used', 'Credit card payment used')}: ${last_4_cc_digits}`;
        }
        if (this.$props.billing.invoice.data.status && this.$props.billing.invoice.data.status !== 'open') {
          return this.$props.billing.invoice.data.status.charAt(0).toUpperCase() + this.$props.billing.invoice.data.status.slice(1);
        }
        return null;
      },
      get_previous_balance() {
        const previous = this.$props.billing.invoice.transactions.filter((x) => x.description === 'Previous balance');
        return previous.reduce((a, c) => a + c.amount, 0);
      },
      payments() {
        return this.$props.billing.invoice.payments.reduce((a, c) => a + c.amount, 0);
      },
      get_balance() {
        const prev_balance = this.get_previous_balance();
        const payments = this.payments();
        return prev_balance - (payments < 0 ? -1 * payments : payments);
      },
      get_amount_due() {
        return this.get_balance() + this.get_new_charges() + this.get_adjustments();
      },
      get_adjustments() {
        const adjustments = this.$props.billing.invoice.adjustments.reduce((a, c) => a + c.amount, 0);
        return adjustments;
      },
      get_new_charges() {
        const total_taxes = this.$props.billing.invoice.taxes.reduce((a, c) => a + c.amount, 0);
        const activity = this.$props.billing.invoice.activity.reduce((a, c) => a + c.amount, 0);
        return activity + total_taxes;
      },
      positive_number(num) {
        if (num < 0) {
          return num * -1;
        }
  
        return num;
      },
      format_money(val) {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
  
        return formatter.format(val / 100);
      },
    },
    filters: {
      format_date: (val) => formatDate(val),
      format_time: (val) => formatTime(val),
      description: (t) => `${t.description}${t.data ? ` <strong>${t.data}</strong>` : ''}`,
      },
  };
  </script>
  