<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-alert
            v-if="alert"
            :message="alert.message"
            :level="alert.level"
            class="mb-6"
        />
        <w-form 
            @submit="submited"
            @changed="delayed_change()"
            :readonly="readonly"
            data-discard="true"
            data-test-id="schedule-form"
        >
            <FormInput :label="l.t('app.name', 'Name')">
                <w-text-field
                    v-if="readonly"
                    v-model="schedule.name"
                    not-clearable
                    :rules="[$validation.required()]"
                    hide-details='auto'
                    maxLength="30"
                    data-test-id="schedule-name-input"
                />
                <w-text-field
                    v-else
                    v-model="schedule.name"
                    :rules="[$validation.required()]"
                    hide-details='auto'
                    maxLength="30"
                    data-test-id="schedule-name-input"
                />
            </FormInput>
            <FormInput :label="l.t('app.type', 'Type')">
                <w-radio-group
                    v-model="type"
                    row
                    hide-details='auto'
               >
                 <v-radio
                   :label="l.t('schedules.daily', 'Daily')"
                   value="daily"
                   data-test-id="schedule-daily-radio"
                 ></v-radio>
                 <v-radio
                   :label="l.t('schedules.weekday-weekend', 'Weekday - Weekend')"
                   value="weekly"
                   data-test-id="schedule-weekly-radio"
                 ></v-radio>
                 <v-radio
                   :label="l.t('app.custom', 'Custom')"
                   value="custom"
                   data-test-id="schedule-cusom-radio"
                 ></v-radio>
               </w-radio-group>
            </FormInput>
            <FormInput :label="l.t('schedules.hours', 'Hours')">
                <v-simple-table class="without-hover-effect" data-test-id="schedule-hours">
                    <thead data-test-id="table-head">
                        <tr data-test-id="table-head-row">
                             <th data-test-id="day"></th>
                            <th data-test-id="day">{{ l.t('schedules.day', 'Day') }}</th>
                            <th data-test-id="schdule">{{ l.t('app.schedule', 'Schedule') }}</th>
                        </tr>
                    </thead>
                    <tbody data-test-id="table-body">
                        <template v-if="type === 'daily'">
                            <tr :key="(reRender_key + 1)" data-test-id="daily-schaedule">
                                <td>
                                    <w-checkbox
                                        v-model="enable_daily"
                                        color="secondary"
                                        class="mx-auto"
                                        :disabled="true"
                                    />
                                </td>
                                <td data-test-id="daily">{{ l.t('schedules.daily', 'Daily') }}</td>
                                <td data-test-id="timeinterval">
                                    <TimeIntervalPicker :disabled="false" :startHour="!default_type === 'daily' ? '09' : schedule.items[0].start_hour" :startMinute="!default_type === 'daily' ? '00' : schedule.items[0].start_minute" :endHour="!default_type === 'daily' ? '17' : schedule.items[0].end_hour" :endMinute="!default_type === 'daily' ? '00' : schedule.items[0].end_minute" @changed="dailyTimeChanged($event, 0)" :required="true" data-test-id="schedule-daily-timeinterval-picker"/>
                                </td>
                            </tr>
                        </template>
                        <template v-if="type === 'weekly'">
                            <tr :key="(reRender_key + 2)" data-test-id="weekely-schedule-weekday">
                                <td>
                                    <w-checkbox
                                        v-model="enable_weekday"
                                        color="secondary"
                                        class="mx-auto"
                                    />
                                </td>
                                <td data-test-id="weekday">{{ l.t('schedules.weekday', 'Weekday') }}</td>
                                <td data-test-id="timeinterval">
                                    <TimeIntervalPicker :disabled="!enable_weekday" :startHour="weekdayItem ? weekdayItem.start_hour : null" :startMinute="weekdayItem ? weekdayItem.start_minute : null" :endHour="weekdayItem ? weekdayItem.end_hour : null" :endMinute="weekdayItem ? weekdayItem.end_minute : null" @changed="weekendTimeChanged($event, 'weekday')" data-test-id="schedule-weekday-timeinterval-picker"/>
                                </td>
                            </tr>
                            <tr :key="(reRender_key + 3)" data-test-id="weekly-schedule-weekend">
                                <td>
                                    <w-checkbox
                                        v-model="enable_weekend"
                                        color="secondary"
                                        class="mx-auto"
                                    />
                                </td>
                                <td data-test-id="weekend">{{ l.t('schedules.weekend', 'Weekend') }}</td>
                                <td data-test-id="timeinterval">
                                    <TimeIntervalPicker :disabled="!enable_weekend" :startHour="weekendItem ? weekendItem.start_hour : null" :startMinute="weekendItem ? weekendItem.start_minute : null" :endHour="weekendItem ? weekendItem.end_hour : null" :endMinute="weekendItem ? weekendItem.end_minute : null" @changed="weekendTimeChanged($event, 'weekend')" :key="JSON.stringify(weekendItem)" data-test-id="schedule-daily-weekend-picker"/>
                                </td>
                            </tr>
                        </template>
                        <template v-if="type === 'custom'">
                            <tr v-for="(item, index) in schedule.items" :key="(reRender_key + 4 + index)" data-test-id="custom-schedule">
                                <td>
                                    <w-checkbox
                                        v-model="enable_custom[index]"
                                        color="secondary"
                                        class="mx-auto"
                                    />
                                </td>
                                <td :data-test-id="item.day">{{ days[item.day] }}</td>
                                <td :data-test-id="`${item.day}-timeinterval`">
                                    <TimeIntervalPicker :disabled="!enable_custom[index]" :startHour="item.start_hour" :startMinute="item.start_minute" :endHour="item.end_hour" :endMinute="item.end_minute" @changed="timeChanged($event, index)" :data-test-id="`schedule-${index}-daily-custom-picker`"/>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </v-simple-table>
            </FormInput>
            <FormInput :label="l.t('schedules.add-to-this-schedule', 'Add to this schedule')">
                 <w-radio-group
                    v-model="enable_add_to_this"
                    row
                    hide-details='auto'
                    :key="enable_add_to_this"
                    data-test-id="add-to-this-schedule"
                >
                    <v-radio
                        :label="l.t('schedules.holiday', 'Holiday')"
                        value="holiday"
                        data-test-id="schedule-holiday-radio"
                    ></v-radio>
                    <v-radio
                        :label="l.t('app.date-range', 'Date range')"
                        value="date_range"
                        data-test-id="schedule-date-range-radio"
                    ></v-radio>
                    <v-icon v-if="!readonly && enable_add_to_this" @click="disable_add_to_this()" class="red--text" data-test-id="schedule-close-add-to-this">mdi-close</v-icon>
                </w-radio-group>
            </FormInput>
            <template v-if="enable_add_to_this === 'holiday'">
                <FormInput :label="l.t('schedules.select-a-holiday', 'Select a holiday')">
                    <div class="d-flex">
                        <w-select
                            v-model="holiday"
                            :items="holidays.map((x) => {
                                return { value: x.id, text: x.name }
                            })"
                            :rules="[$validation.required()]"
                            :placeholder="l.t('schedules.select-a-holiday', 'Select a holiday')"
                            hide-details="auto"
                            data-test-id="schedule-holidays-select"
                        />
                        <w-tooltip v-if="holiday !== null" bottom no-carret>
                          <template v-slot:activator>
                            <v-icon
                              @click="addDetails"
                              class="ml-3 mt-4"
                              color="primary"
                              data-test-id="schedule-add-holiday-btn"
                            >
                              mdi-calendar-plus
                            </v-icon>
                          </template>
                          <span>{{ l.t('schedules.add-this-holiday', 'Add this holiday') }}</span>
                        </w-tooltip>
                    </div>
                </FormInput>
            </template>
            <template v-if="enable_add_to_this === 'date_range'">
                <FormInput :label="l.t('app.date-range', 'Date range')">
                    <div class="d-flex">
                        <DatePickerInput
                            :label="l.t('app.start-date', 'Start date')"
                            :value="date_range.start_date"
                            :rules="[$validation.required()]"
                            @changed="date_range.start_date = $event"
                            :key="date_range.start_date"
                            data-test-id="schedule-start-date-input"
                        />
                        <span class="mx-3 my-auto">&mdash;</span>
                        <DatePickerInput
                            :label="l.t('app.end-date', 'End date')"
                            :value="date_range.end_date"
                            :rules="[$validation.required()]"
                            @changed="date_range.end_date = $event"
                            :key="date_range.end_date"
                            data-test-id="schedule-end-date-input"
                        />
                        <w-tooltip v-if="date_range.start_date && date_range.end_date" bottom no-carret>
                          <template v-slot:activator>
                            <v-icon
                              @click="addDetails('range')"
                              class="ml-3 mt-4"
                              color="primary"
                              data-test-id="schedule-add-details-btn"
                            >
                              mdi-calendar-plus
                            </v-icon>
                          </template>
                          <span>{{ l.t('schedules.add-this-date-range', 'Add this date range') }}</span>
                        </w-tooltip>
                    </div>
                </FormInput>
            </template>
            <template v-if="addedItems.length">
                <FormInput :label="l.t('schedules.date-holiday-list', 'Date Range / Holiday List')">
                    <v-simple-table class="tight-table" data-test-id="schedule-date-range-holidays-table">
                        <thead data-test-id="table-head">
                            <tr data-test-id="table-head-row">
                                <th data-test-id="day">
                                    {{ l.t('schedules.day', 'Day') }}
                                </th>
                                <th data-test-id="use-once">
                                    <span class="mr-1">{{ l.t('schedules.use-once', 'Use once') }}</span>
                                    <InfoLink
                                    >
                                        {{ l.t(
                                            'schedules.use-once-explintaion',
                                            'By default, this holiday will repeat annually. Selecting \'Use Once\' will use this holiday once.'
                                        ) }}
                                    </InfoLink>
                                </th>
                                <th class="w-100" data-test-id="schedule">
                                    {{ l.t('app.schedule', 'Schedule') }}
                                </th>
                                <th style="width: 5%" data-test-id="days-before">
                                    <span class="mr-1">{{ l.t('schedules.days-before', 'Days before') }}</span>
                                    <InfoLink
                                        data-test-id="schedule-days-before-help"
                                    >
                                        {{ l.t('schedules.days-before-explintaion', 'Start this schedule [N] days before the selected holiday. For example, if you select Independence Day (July 4), and set days before to 4, the schedule will start on June 30.') }}
                                    </InfoLink>
                                </th>
                                <th  style="width: 5%" data-test-id="days-after">
                                    <span class="mr-1">{{ l.t('schedules.days-after', 'Days after') }}</span>
                                    <InfoLink
                                        data-test-id="schedule-days-after-help"
                                    >
                                        {{ l.t('schedules.days-after-explintaion', 'End this schedule [N] days after the selected holiday. For example, if you select Independence Day (July 4), and set days after to 6, the schedule will end on July 10.') }}
                                    </InfoLink>
                                </th>
                                <th data-test-id="remove"><br/></th>
                            </tr>
                        </thead>
                        <tbody data-test-id="table-body">
                            <tr v-for="(addedItem, i) in this.$data.addedItems" :data-test-id="`schedule-h-dr-${i}-item-row`">
                                <td data-test-id="name-or-date">{{ nameOrDate(addedItem) }}</td>
                                <td data-test-id="switch">
                                    <w-switch
                                        v-model="addedItem.use_once"
                                        hide-details='auto'
                                        :data-test-id="`schedule-h-dr-${i}-item-use-once`"
                                    />
                                </td>
                                <td data-test-id="timeinterval">
                                    <TimeIntervalPicker
                                        :startHour="addedItem.start_hour"
                                        :startMinute="addedItem.start_minute"
                                        :endHour="addedItem.end_hour"
                                        :endMinute="addedItem.end_minute"
                                        @changed="addedItemTimeChanged($event, i)"
                                        :required="true"
                                        :preventClear="true"
                                        :small="true"
                                        :key="addedItem.start_hour"
                                        :data-test-id="`schedule-h-dr-${i}-timeinterval-picker`"
                                    />
                                </td>
                                <td data-test-id="holiday-days-before">
                                    <w-text-field
                                        v-if="addedItem.added_type === 'holiday'"
                                        v-model="addedItem.days_before"
                                        not-clearable
                                        type="number"
                                        min="0"
                                        class="w-100"
                                        hide-details='auto'
                                        :data-test-id="`schedule-h-dr-${i}-item-holiday-days-before-input`"
                                    />
                                    <span v-else>&mdash;</span>
                                </td>
                                <td data-test-id="holiday-days-after">
                                    <w-text-field
                                        v-if="addedItem.added_type === 'holiday'"
                                        v-model="addedItem.days_after"
                                        not-clearable
                                        type="number"
                                        min="0"
                                        class="w-100"
                                        hide-details='auto'
                                        :data-test-id="`schedule-h-dr-${i}-item-dayes-after-input`"
                                    />
                                    <span v-else>&mdash;</span>
                                </td>
                                <td data-test-id="remove">
                                    <RemoveFromListButton v-if="!readonly" @click="$delete(addedItems, i)" :data-test-id="`schedule-h-dr-${i}-item-delete-btn`" />
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </FormInput>
            </template>
            <DefaultModal
                v-model="show_error_modal"
                @close="show_error_modal = false"
                width="500"
                data-test-id="schedule-error-modal"
            >
                <template v-slot:title>
                    {{l.t('app.error', 'Error')}}
                </template>
                {{ error }}
                <template v-slot:buttons>
                    <w-btn color="secondary" @click="show_error_modal = false">{{l.t('app.close', 'Close')}}</w-btn>
                </template>
            </DefaultModal>
            <FormInput :label="l.t('app.timezone', 'Timezone')">
                 <w-radio-group
                    v-model="enable_timezone"
                    row
                    hide-details='auto'
                    data-test-id="timezone-radio-buttons"
                >
                  <v-radio
                    :label="l.t('schedules.use-default-timezone', 'Use default timezone')"
                    :value="false"
                    data-test-id="schedule-default-tz-radio"
                  ></v-radio>
                  <v-radio
                    :label="l.t('schedules.use-a-custom-timezone-for-this-schedule', 'Use a custom timezone for this schedule')"
                    :value="true"
                    data-test-id="schedule-custom-tz-radio"
                  ></v-radio>
                </w-radio-group>
                <TimezoneSelector v-if="enable_timezone" v-model="schedule.timezone" data-test-id="schedule-timezone-selector"></TimezoneSelector>
            </FormInput>
            <FormInput>
                <w-btn type="submit" :disabled="readonly || !may_submit" class="primary" data-test-id="schedule-submit-btn">{{ l.t('app.save', 'Save') }}</w-btn>
            </FormInput>
        </w-form>
    </div>
</template>

<script>
import l from '../../libs/lang';
import EventBus from '../../libs/EventBus';
import InfoLink from '../elements/InfoLink.vue';
import FormInput from '../elements/form/FormInput.vue';
import DefaultModal from '../elements/modal/DefaultModal.vue';
import TimezoneSelector from '../elements/TimezoneSelector.vue';
import DatePickerInput from '../elements/form/DatePickerInput.vue';
import TimeIntervalPicker from '../elements/TimeIntervalPicker.vue';
import RemoveFromListButton from '../elements/RemoveFromListButton.vue';

export default {
    props: ['_schedule', 'readonly'],
    components: {
        InfoLink,
        FormInput,
        DefaultModal,
        DatePickerInput,
        TimezoneSelector,
        TimeIntervalPicker,
        RemoveFromListButton,
    },
    data() {
        return {
            l,
            loading: false,
            reRender_key: 5,
            enable_daily: true,
            enable_weekday: false,
            enable_weekend: false,
            enable_custom: [],
            alert: null,
            schedule: null,
            type: 'daily',
            enable_add_to_this: null,
            enable_timezone: false,
            holiday: null,
            date_range: {
                start_date: null,
                end_date: null,
            },
            days: {
                'S': l.t('schedules.saturday', 'Saturday'),
                'Su': l.t('schedules.sunday ', 'Sunday  '),
                'M': l.t('schedules.monday', 'Monday'),
                'T': l.t('schedules.tuesday', 'Tuesday'),
                'W': l.t('schedules.wednesday', 'Wednesday'),
                'Th': l.t('schedules.thursday', 'Thursday'),
                'F': l.t('schedules.friday', 'Friday'),
            },
            holidays: [
                {id: 1, name: l.t('schedules.new-years-day', 'New Years Day')},
                {id: 2, name: l.t('schedules.martin-luther-king-day', 'Martin Luther King Day')},
                {id: 3, name: l.t('schedules.presidents-day', 'Presidents Day')},
                {id: 4, name: l.t('schedules.memorial-day', 'Memorial Day')},
                {id: 5, name: l.t('schedules.independence-day', 'Independence Day')},
                {id: 6, name: l.t('schedules.labor-day', 'Labor Day')},
                {id: 7, name: l.t('schedules.columbus-day', 'Columbus Day')},
                {id: 8, name: l.t('schedules.veterans-day', 'Veterans Day')},
                {id: 9, name: l.t('schedules.thanksgiving', 'Thanksgiving')},
                {id: 10, name: l.t('schedules.black-friday', 'Black Friday')},
                {id: 11, name: l.t('schedules.christmas-eve', 'Christmas Eve')},
                {id: 12, name: l.t('schedules.Christmas', 'Christmas')},
                {id: 13, name: l.t('schedules.new-years-eve', 'New Years Eve')},
            ],
            addedItems: [],
            show_explination: null,
            error: null,
            show_error_modal: false,
            may_submit: false,
            default_form: {
                schedule: {},
                enable_add_to_this: null,
                date_range: {},
                addedItems: [],
                enable_timezone: null
            },
            default_type: null,
        };
    },
    created() {
        this.$data.loading = true;
        this.$data.schedule = this.$props._schedule;
        this.checkIfThereIsOnlyHolidayOrRangeItems();

        const holidays = this.$data.schedule.items.filter((x) => x.holiday_id);
        if (holidays.length) {
            this.$data.enable_add_to_this = 'holiday';
            for (const h of holidays) {
                const holiday = this.$data.holidays.find((x) => x.id === h.holiday_id);
                h.added_type = 'holiday';
                h.holiday_id = holiday.id;
                h.name = holiday.name;
            }
            this.$data.addedItems = this.$data.addedItems.concat(holidays);
        }
        const ranges = this.$data.schedule.items.filter((x) => x.start_date && x.end_date && !x.holiday_id);
        if (ranges.length) {
            if (!this.$data.enable_add_to_this) this.$data.enable_add_to_this = 'date_range';

            for (const range of ranges) range.added_type = 'range';

            this.$data.addedItems = this.$data.addedItems.concat(ranges);
        }
        if (this.$data.addedItems.length) {
            this.$data.addedItems = this.$data.addedItems.sort((a, b) => a.rank - b.rank);
            const ids = [];
            for (const item of this.$data.addedItems) {
                ids.push(item.id);
                item.use_once = !!item.use_once;
            }
            this.$data.schedule.items = this.$data.schedule.items.filter((x) => !ids.includes(x.id));
        }
        this.$data.type = this.whatTypeIsIt();
        if (this.$data.schedule.timezone) {
            this.$data.enable_timezone = true;
        }

        this.checkMissingItems();
        this.get_enabled_days();
        this.$data.default_type = this.type;
        this.$data.default_form.schedule = JSON.parse(JSON.stringify(this.$data.schedule));
        this.$data.default_form.enable_add_to_this = this.$data.enable_add_to_this;
        this.$data.default_form.date_range = JSON.parse(JSON.stringify(this.$data.date_range));
        this.$data.default_form.addedItems = this.$data.addedItems;
        this.$data.default_form.enable_timezone = this.$data.enable_timezone;
        this.$data.default_form.enable_daily = this.$data.enable_daily;
        this.$data.default_form.enable_weekday = this.$data.enable_weekday;
        this.$data.default_form.enable_weekend = this.$data.enable_weekend;
        this.$data.default_form.enable_custom = JSON.parse(JSON.stringify(this.$data.enable_custom));
        this.$data.loading = false;
    },
    methods: {
        async submited(e) {
            const payload = { ...this.$data.schedule};
            if (e) e.preventDefault();
            this.prepareScheduleForUpdating();
            if (this.type === 'custom') {
                const items = [...payload.items];
                let index = 0;
                for (const item of items) {
                    item.is_enabled = this.enable_custom[index];
                    index++;
                }
                payload.items = items.filter((x) => x.is_enabled);
            }
            if (this.type === 'weekly') {
                if (this.enable_weekday && !this.enable_weekend) {
                    payload.items = JSON.parse(JSON.stringify(payload.items)).filter((x) => ['M', 'T', 'W', 'Th', 'F'].includes(x.day));
                }
                if (!this.enable_weekday && this.enable_weekend) {
                    payload.items = JSON.parse(JSON.stringify(this.$data.schedule.items)).filter((x) => this.weekendDays.includes(x.day));
                }
            }
            this.$emit('submited', payload);
        },
         delayed_change() {
            setTimeout(() => this.form_changed(), 10);
        },
        form_changed() {
            this.$forceUpdate();
            const new_form_values = {
                schedule: {...this.$data.schedule},
                enable_add_to_this: this.$data.enable_add_to_this,
                date_range: {...this.$data.date_range},
                addedItems: [...this.$data.addedItems],
                enable_timezone: this.$data.enable_timezone,
                enable_daily: this.$data.enable_daily,
                enable_weekday: this.$data.enable_weekday,
                enable_weekend: this.$data.enable_weekend,
                enable_custom: JSON.parse(JSON.stringify(this.$data.enable_custom)),
            };
            if (JSON.stringify(this.default_form) === JSON.stringify(new_form_values) && this.$data.default_type === this.$data.type) {
                this.may_submit = false;
                EventBus.$emit('item_updated');
            } else this.may_submit = true;
        },
        disable_add_to_this() {
            this.enable_add_to_this = null;
            setTimeout(() => this.form_changed(), 10);
        },
        async get_enabled_days() {
            if (this.type === 'custom') {
            let index = 0;
            for (const item of this.$data.schedule.items) {
                if (item.id) this.enable_custom[index] = true;
                else this.enable_custom[index] = false;
                index++;
                }
            }
            if (this.type === 'weekly') {
                const week_days = JSON.parse(JSON.stringify(this.$data.schedule.items)).filter((x) => ['M', 'T', 'W', 'Th', 'F'].includes(x.day) && x.id);
                const weekend_days = JSON.parse(JSON.stringify(this.$data.schedule.items)).filter((x) => ['S', 'Su'].includes(x.day) && x.id);
                if (week_days.length === 5) this.enable_weekday = true; else this.enable_weekday = false;
                if (weekend_days.length === 2) this.enable_weekend = true; else this.enable_weekend = false;
            }
            if (this.$data.type === 'daily') {
               for (const item of this.$data.schedule.items) {
                if (!item.id) item.id = 1;
               }
            }
            this.$forceUpdate();
        },
        prepareScheduleForUpdating() {
            if (this.type === 'daily') {
                const exampleItem = JSON.parse(JSON.stringify(this.$data.schedule.items[0]));
                this.$data.schedule.items = this.empty_items.map((x) => {
                    x.start_hour = exampleItem.start_hour;
                    x.start_minute = exampleItem.start_minute;
                    x.end_hour = exampleItem.end_hour;
                    x.end_minute = exampleItem.end_minute;

                    return x;
                });
            } else if (this.type === 'weekly') {
                const exampleWeekend = JSON.parse(JSON.stringify(this.weekendItem));
                const exampleWeekday = JSON.parse(JSON.stringify(this.weekdayItem));

                this.$data.schedule.items = this.$data.schedule.items.map((x) => {
                    if (this.weekdaysDays.includes(x.day)) {
                        const newItem = {...exampleWeekday};
                        newItem.day = x.day;

                        return newItem;
                    }
                    const newItem = {...exampleWeekend};
                    newItem.day = x.day;

                    return newItem;
                });
            }
            for (const item of this.$data.schedule.items) item.rank = 9999;
            for (const item of this.$data.addedItems) {
                item.days_before = item.days_before ? parseInt(item.days_before) : null;
                item.days_after = item.days_after ? parseInt(item.days_after) : null;
            }
            this.$data.schedule.items = this.$data.addedItems.concat(this.$data.schedule.items);
            for (const item of this.$data.schedule.items) {
                item.use_once = !!item.use_once;
                if (item.use_once) {
                    let nextYear = new Date().getFullYear() + 1;
                    nextYear = Math.floor(new Date(nextYear.toString()).getTime() / 1000);
                    item.expiration_date = nextYear;
                }
            }
            if (!this.$data.enable_timezone) {
                this.$data.schedule.timezone = null;
            }
            for (const index in this.$data.schedule.items) {
               if (this.$data.schedule.items[index]) {
                const item = this.$data.schedule.items[index];
                if (!item.start_hour && !item.start_minute && !item.end_hour && !item.end_minute) {
                    this.$data.schedule.items[index].to_be_removed = true;
                }
               }
            }
            // this.$data.schedule.items = this.$data.schedule.items.filter((x) => !x.to_be_removed);
        },
        addDetails(type) {
            const item = {...this.empty_item};
            item.day = 'M';
            if (type === 'range') {
                if (!this.$data.date_range.start_date || !this.$data.date_range.end_date) {
                    return null;
                }
                item.added_type = 'range';
                item.start_date = this.stringToTime(this.$data.date_range.start_date);
                item.end_date = this.stringToTime(this.$data.date_range.end_date);
                this.$data.addedItems.push(item);
                this.$data.date_range = {
                    start_date: null,
                    end_date: null,
                };
            } else {
                if (!this.$data.holiday) return null;
                const holiday = this.$data.holidays.find((x) => x.id === this.$data.holiday);
                if (!holiday) {
                    this.$data.error = l.t('schedules.please-choose-from-the-list', 'Please choose a holiday from the list');
                    return null;
                }
                item.added_type = 'holiday';
                item.holiday_id = holiday.id;
                item.name = holiday.name;
                this.$data.addedItems.push(item);
                this.$data.holiday = null;
            }

            return true;
        },
        checkIfThereIsOnlyHolidayOrRangeItems() {
            if (this.$data.schedule.items.filter((x) => !x.holiday_id && !x.start_date).length) return false;
            const newItems = this.empty_items;
            for (const item of newItems) {
                item.start_hour = 9;
                item.start_minute = 0;
                item.end_hour = 17;
                item.end_minute = 0;
            }
            this.$data.schedule.items = this.$data.schedule.items.concat(newItems);

            return true;
        },
        stringToTime(string) {
            return string ? Math.floor(new Date(string).getTime() / 1000) : null;
        },
        dateToString(date) {
            return date ? new Date(date * 1000).toISOString().split('T')[0] : null;
        },
        checkMissingItems() {
            if (this.$data.schedule.items.length < 7) {
                for (const day of this.allDays) {
                    if (!this.$data.schedule.items.find((x) => x.day === day)) {
                        const newDay = {...this.empty_item};
                        newDay.day = day;
                        this.$data.schedule.items.push(newDay);
                    }
                }
            }
            this.sortItems();
        },
        sortItems() {
            const items = [...this.$data.schedule.items];
            this.$data.schedule.items = [];
            for (const day of this.allDays) {
                const item = items.find((x) => x.day === day);
                if (item) {
                    this.$data.schedule.items.push(item);
                }
            }
        },
        dailyTimeChanged(time) {
            this.$data.schedule.items = this.$data.schedule.items.map((x) => {
                const y = this.addTime(x, time, 'start');
                return y;
            });
            this.$data.schedule.items = this.$data.schedule.items.map((x) => {
                const y = this.addTime(x, time, 'end');
                return y;
            });
        },
        weekendTimeChanged(time, type) {
            if (type === 'weekday') {
                this.$data.schedule.items = this.$data.schedule.items.map((x) => {
                    let y = x;
                    if (this.weekdaysDays.includes(x.day)) {
                        y = this.addTime(x, time, 'start');
                    }
                    return y;
                });

                this.$data.schedule.items = this.$data.schedule.items.map((x) => {
                    if (this.weekdaysDays.includes(x.day)) {
                        x = this.addTime(x, time, 'end');
                    }
                    return x;
                });

                return true;
            }
            this.$data.schedule.items = this.$data.schedule.items.map((x) => {
                let y = x;
                if (this.weekendDays.includes(x.day)) {
                   y = this.addTime(x, time, 'start');
                }
                return y;
            });
            this.$data.schedule.items = this.$data.schedule.items.map((x) => {
                let y = x;
                if (this.weekendDays.includes(x.day)) {
                    y = this.addTime(x, time, 'end');
                }
                return y;
            });

            return true;
        },
        addTime(x, time, type) {
            if (time[type]) {
                x[`${type}_hour`] = time[type].hour;
                x[`${type}_minute`] = time[type].minute;
            } else {
                x[`${type}_hour`] = null;
                x[`${type}_minute`] = null;
            }

            return x;
        },
        timeChanged(time, index) {
            if (time.start) {
                this.$data.schedule.items[index].start_hour = time.start.hour;
                this.$data.schedule.items[index].start_minute = time.start.minute;
            } else {
                this.$data.schedule.items[index].start_hour = null;
                this.$data.schedule.items[index].start_minute = null;
            }
            if (time.end) {
                this.$data.schedule.items[index].end_hour = time.end.hour;
                this.$data.schedule.items[index].end_minute = time.end.minute;
            } else {
                this.$data.schedule.items[index].end_hour = null;
                this.$data.schedule.items[index].end_minute = null;
            }
        },
        addedItemTimeChanged(time, index) {
          if (time.start) {
              this.$data.addedItems[index].start_hour = time.start.hour;
              this.$data.addedItems[index].start_minute = time.start.minute;
          } else {
              this.$data.addedItems[index].start_hour = null;
              this.$data.addedItems[index].start_minute = null;
          }
          if (time.end) {
              this.$data.addedItems[index].end_hour = time.end.hour;
              this.$data.addedItems[index].end_minute = time.end.minute;
          } else {
              this.$data.addedItems[index].end_hour = null;
              this.$data.addedItems[index].end_minute = null;
          }
        },
        checkAddToThis(type) {
            if (this.$data.enable_add_to_this === type) {
                return this.$data.enable_add_to_this = null;
            }
            return this.$data.enable_add_to_this = type;
        },
        isDaily() {
            if (!this.$data.schedule.items.length) return true;
            if (this.$data.schedule.items.length === 1 && !this.$data.schedule.items[0].day) return true;
            if (this.$data.schedule.items.length !== 7) return false;

            let exampleItem = JSON.parse(JSON.stringify(this.$data.schedule.items[0]));
            exampleItem = this.cleanDay(exampleItem);
            exampleItem = JSON.stringify(exampleItem);

            const items = JSON.parse(JSON.stringify(this.$data.schedule.items));

            return items.every((x) => {
                x = this.cleanDay(x);
                return JSON.stringify(x) === exampleItem;
            });
        },
        isWeekday() {
            if (!this.$data.schedule.items.length) return false;
            const items = JSON.parse(JSON.stringify(this.$data.schedule.items)).filter((x) => ['M', 'T', 'W', 'Th', 'F'].includes(x.day));
            if (items.length !== 5 && items.length !== 0) return false;
            let weekDay = this.weekdayItem;
            weekDay = {...weekDay};
            weekDay = this.cleanDay(weekDay);
            weekDay = JSON.stringify(weekDay);

            return items.every((x) => {
                const y = this.cleanDay(x);

                return JSON.stringify(y) === weekDay;
            });
        },
        isWeekend() {
            if (!this.$data.schedule.items.length) return false;
            const items = JSON.parse(JSON.stringify(this.$data.schedule.items)).filter((x) => this.weekendDays.includes(x.day));
            if (items.length !== 2 && items.length !== 0) return false;

            let weekendDay = this.weekendItem;
            weekendDay = JSON.parse(JSON.stringify(weekendDay));
            weekendDay = this.cleanDay(weekendDay);
            weekendDay = JSON.stringify(weekendDay);
            return items.every((x) => {
                const y = this.cleanDay(x);
                return JSON.stringify(y) === weekendDay;
            });
        },
        isWeekly() {
            const {length} = this.$data.schedule.items;
            if (length !== 2 && length !== 5 && length !== 7) {
                return false;
            }
            return this.isWeekday() && this.isWeekend();
        },
        isCustom() {
            return !this.isDaily() && !this.isWeekly();
        },
        whatTypeIsIt() {
            if (this.isDaily()) return 'daily';
            if (this.isWeekly()) return 'weekly';
            return 'custom';
        },
        cleanDay(day) {
            delete day.id;
            delete day.day;
            delete day.rank;

            return day;
        },
        nameOrDate(addedItem) {
            if (addedItem.name) return addedItem.name;
            let start = this.dateToString(addedItem.start_date);
            let end = this.dateToString(addedItem.end_date);
            let date = '';
            if (start) {
                start = start.split('-');
                start[0] = start[0].substring(2);
                start = [start[1], start[2], start[0]];
                date += start.join('/');
            }
            if (start && end) {
                date += '-';
            }
            if (end) {
                end = end.split('-');
                end[0] = end[0].substring(2);
                end = [end[1], end[2], end[0]];
                date += end.join('/');
            }

            return date;
        },
    },
    computed: {
        weekdayItem() {
            const weekday = this.$data.schedule.items.find((x) => this.weekdaysDays.includes(x.day) && !Number.isNaN(x.start_hour) && !Number.isNaN(x.start_minute) && !Number.isNaN(x.end_hour) && !Number.isNaN(x.end_minute));

            return weekday || this.empty_item;
        },
        weekendItem() {
            const weekend = this.$data.schedule.items.find((x) => this.weekendDays.includes(x.day) && !Number.isNaN(x.start_hour) && !Number.isNaN(x.start_minute) && !Number.isNaN(x.end_hour) && !Number.isNaN(x.end_minute));
            return weekend || this.empty_item;
        },
        weekdaysDays() {
            return Object.keys(this.$data.days).filter((x) => ['M', 'T', 'W', 'Th', 'F'].includes(x));
        },
        weekendDays() {
            return Object.keys(this.$data.days).filter((x) => ['S', 'Su'].includes(x));
        },
        allDays() {
            return Object.keys(this.$data.days);
        },
        empty_item() {
            return {
                holiday_id: null,
                days_before: null,
                days_after: null,
                start_date: 0,
                end_date: 0,
                start_hour: null,
                start_minute: null,
                end_hour: null,
                end_minute: null,
                voip_destination_id: 0,
                use_once: null,
                expiration_date: null
            };
        },
        empty_items() {
            const items = [];
            for (const day of this.allDays) {
                const item = {...this.empty_item};
                item.day = day;
                items.push(item);
            }
            return items;
        },
    },
    watch: {
        error(val) {
            this.$data.show_error_modal = !!val;
        },
        enable_timezone(val) {
            if (!val) this.$data.schedule.timezone = '';
            else if (this.$data.default_form.schedule.timezone !== '') this.$data.schedule.timezone = this.$data.default_form.schedule.timezone;
        },
        type(val) {
            if (val === this.default_type) {
                this.$data.schedule = JSON.parse(JSON.stringify(this.$data.default_form.schedule));
            }
            this.get_enabled_days();
        }
    }
};
</script>
