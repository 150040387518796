<template>
    <DefaultModal
          v-on="$listeners"
          v-bind="$attrs"
          :width="$attrs.width || 500"
          data-test-id="device-info-modal"
        >
        <template v-if="!loading" v-slot:title>
          {{sip_can_be_visible ? l.t('devices.devide-details-sip', 'Device details/SIP') : l.t('devices.devide-details', 'Device details')}}
        </template>
        <w-loader v-if="loading" class="ma-12"/>
        <VerticalTable
          v-else
          :items="details_and_sip()"
        />
        <template v-if="!loading" v-slot:buttons>
          <w-btn color="secondary" @click="$emit('close')">{{l.t('app.cancel', 'Cancel')}}</w-btn>
          <w-btn text>
            <CopyToClipboard
                :copy="text_to_be_copied()"
                :data-test-id="`devices-item-${item.id}-copy-to-clipboard`"
              />
          </w-btn>
        </template>
      </DefaultModal>
</template>
<script>
import {is_csr} from 'phoenix-session-helpers';
import {stringifyAddress} from 'formatters'
import l from '../../../libs/lang';
import Device from '../../../models/Device';
import DefaultModal from './DefaultModal.vue';
import VerticalTable from '../VerticalTable.vue';
import CopyToClipboard from '../CopyToClipboard.vue'; 

export default {
    props: {
        id: {
            type: [String, Number,],
            requried: false,
        },
        device: {
            required: false,
            type: Object,
        },
        all_info: {
            type: Boolean,
            default: false,
        }
    },
    components: { DefaultModal, CopyToClipboard, VerticalTable },
    data() {
        return {
            l,
            item: null,
            loading: false,
            user_is_csr: false,
            sip_can_be_visible: false,
        }
    },
    async created() {
        this.$data.loading = true;
        this.$data.user_is_csr = await is_csr(this.$session);
        if (this.$props.device) {
            this.$data.item = this.$props.device;
        } else if (this.$props.id) {
            this.$data.item = await this.$session.get_item(`/devices/${this.$props.id}`);
        } else {
            throw new Error('ID or device value required');
        }
        this.$data.sip_can_be_visible = Device.sip_can_be_visible(this.user_is_csr, this.$data.item.code, this.$data.item.type)
        this.$data.loading = false;
    },
    methods: {
        text_to_be_copied() {
            const text = [];
            const details = this.details_and_sip();
            for (const detail of details) {
                text.push(`${detail.text}: ${detail.value}`);
            }
            return text.join('\n');
        },
        upperFirstCase(val) {
            if (!val) return '';
            return val.charAt(0).toUpperCase() + val.slice(1).split('_').join(' ').split('-')
                .join(' ');
        },
        details_and_sip() {
            let items = [
                {text: l.t('app.ID', 'ID'), value: this.$data.item.id},
                {text: l.t('devices.device-name', 'Device name'), value: Device.device_name(this.$data.item)},
                {text: l.t('app.type', 'Type'), value: this.upperFirstCase(this.$data.item.type)},
                {text: l.t('app.mac-address', 'MAC address'), value: this.$data.item.mac_address || '–'},
                {text:l.t('app.serial-number', 'Serial number'), value: this.$data.item.serial_number || '–'},
            ];
            for (const line of this.$data.item.lines) {
                items.push({
                    text: `${l.t('app.line', 'Line')} ${line.line}`,
                    value: `${l.t('app.extension', 'Extension')} ${line.extension.extension}`,
                });
            }
            if (this.$props.all_info) {
                items.push({
                    text: l.t('app.e911-address', 'E911 address'),
                    value: stringifyAddress(this.$data.item.address),
                });
            }
            if (this.sip_can_be_visible) {
                items = items.concat([
                    {text: l.t('devices.sip-proxy', 'SIP proxy'), value: this.$data.item.sip_authentication.host},
                    {text: l.t('app.sip-port', 'SIP Port'), value: this.$data.item.sip_authentication.port},
                    {text: l.t('app.sip-username', 'SIP Username'), value: this.$data.item.sip_authentication.username},
                    {text: l.t('app.sip-password', 'SIP Password'), value: this.$data.item.sip_authentication.password},
                ]);
            }

            return items;
        },
    }
}
</script>