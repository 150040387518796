<template>
  <div>
    <w-loader v-if="listener.loading"/>
    <div v-else>
      <w-alert
        v-if="listener.alert"
        :message="listener.alert.message"
        :level="listener.alert.level"
        :closable="listener.enable_close_alert"
        @closed="listener.alert = null"
        class="mb-6"
      />
      <w-form v-if="listener.item" @changed="may_submit = true" @submit="listener.submitted($event)" data-discard="true">
        <FormInput :label="l.t('app.application', 'Application')">
          <w-select
            v-model="listener.application"
            :items="[
              {value: 'google-analytics', text: l.t('listeners.google-analytics', 'Google analytics') },
              {value: 'sms-autoresponder', text: l.t('listeners.sms-autoresponder', 'SMS autoresponder') },
            ]"
            hide-details="auto"
            data-test-id="app-application-select"
          />
        </FormInput>
        <template v-if="!disable_extension_selector">
          <FormInput :label="l.t('app.level', 'Level')">
            <w-radio-group
              v-model="listener.level"
              row
              hide-details='auto'
            >
              <v-radio
                :label="l.t('app.account', 'Account')"
                value="account"
                data-test-id="apps-account-radio"
              ></v-radio>
              <v-radio
                :label="l.t('app.extension', 'Extension')"
                value="extension"
                data-test-id="apps-extension-radio"
              ></v-radio>
            </w-radio-group>
          </FormInput>
          <template v-if="listener.level === 'extension'">
            <FormInput :label="l.t('app.extension', 'Extension')">
              <APIAutocomplete
                mode="extensions"
                v-model="listener.extension"
                data-test-id="apps-extension-selector"
              />
            </FormInput>
          </template>
        </template>
        <template
          v-if="
            listener.application &&
              Object.keys(listener.subscriptions[listener.application])
                .length > 1
          "
        >
        <FormInput :label="l.t('listeners.trigger', 'Trigger')">
          <w-radio-group
            v-model="listener.subscriptionsType"
            :rules="[$validation.required()]"
            row
            hide-details='auto'
          >
            <v-radio
              v-for="(tags, value) in listener.subscriptions[
                listener.application
              ]"
             :value="value"
              :label="tags.label"
              :data-test-id="`apps-${value}-radio`"
            /></v-radio>
          </w-radio-group>
        </FormInput>
      </template>
      <template v-if="listener.callback.config">
        <GoogleAnalytics
          v-if="listener.application === 'google-analytics'"
          :_config="listener.callback.config"
          @changed="listener.callback.config = $event"
          data-test-id="apps-google-analytics"
        />
        <div v-if="listener.application === 'sms-autoresponder'">
          <ListenerFilters v-model="listener[':filters']" />
          <SMSAutoresponder
            :_config="listener.callback.config"
            @changed="listener.callback.config = $event"
            data-test-id="apps-sms-autoresponder"
          />
        </div>
      </template>
      <FormInput>
        <w-btn type="submit" class="primary mt-3" :disabled="!may_submit || !listener.application" data-test-id="apps-submit-btn">
          {{ l.t("app.save", "Save") }}
        </w-btn>
      </FormInput>
      </w-form>
    </div>
  </div>
</template>

<script>
import {vueComponent} from 'helpers';
import l from '../../libs/lang';
import Listener from '../../models/Listener';
import FormInput from '../elements/form/FormInput.vue';
import ListenerFilters from './applications/ListenerFilters.vue';
import SMSAutoresponder from './applications/SMSAutoresponder.vue';
import APIAutocomplete from '../elements/form/APIAutocomplete.vue';
import GoogleAnalytics from './applications/google-analytics/master.vue';

export default {
  props: ['_listener', 'disable_extension_selector'],
  components: {
    APIAutocomplete,
    FormInput,
    GoogleAnalytics,
    ListenerFilters,
    SMSAutoresponder,
  },
  data() {
    return {
      l,
      may_submit: false,
      listener: new Listener(
        this.$session,
        vueComponent(this),
      ),
    };
  },
  async created() {
    if (this.$props._listener) {
      this.listener.item = this.$props._listener;
    } else if (this.$route.params.id) {
      await this.listener.getItem(this.$route.params.id);
    }
  },
  filters: {
    upperFirst(tags) {
      return tags.map((x) => x.charAt(0).toUpperCase() + x.slice(1)).join(', ');
    },
  },
};
</script>
