/* eslint array-callback-return : "off" */
import { consoleUserCanAccessPageMiddleware } from 'helpers'
import l from './lang'
import router from '../router'

class SidebarRouter {
    /**
     * @param {object} session - phoenix-api-js-client object -available in components as this.$session
     * @param {number} branding - branding object - available in components as this.$branding
     */
    constructor (session, branding) {
        this.session = session
        this.branding = branding
        this._routes = null
        this.allowed_routes = []
        this.dev_mode = null
        this.dev_feature = 'console.show_developer_feature'
    }

    static report_routes = {
        title: l.t('app.reports', 'Reports'),
        icon: '$vuetify.icons.paper_file',
        routes: [
            {
                name: 'reports.show',
                long_title: l.t('reports.sms-delivery-status-report', 'SMS delivery status report'),
                title: l.t('reports.sms-delivery-status', 'SMS delivery status'),
                params: {
                    type: 'sms'
                }
            },
            {
                name: 'reports.show',
                title: l.t('reports.call-summary', 'Call summary'),
                long_title: l.t('reports.call-summary-report', 'Call summary report'),
                params: {
                    type: 'summary'
                }
            },
            {
                name: 'reports.show',
                title: l.t('reports.call-types', 'Call types'),
                long_title: l.t('reports.call-types-report', 'Call types report'),
                params: {
                    type: 'types'
                }
            },
            {
                name: 'reports.show',
                title: l.t('reports.calls-by-date', 'Calls by date'),
                long_title: l.t('reports.calls-by-date-report', 'Calls by date report'),
                params: {
                    type: 'traffic'
                }
            },
            {
                name: 'reports.show',
                title: l.t('reports.calls-geo-distribution', 'Calls geo distribution'),
                long_title: l.t('reports.calls-geo-distribution-report', 'Calls geo distribution report'),
                params: {
                    type: 'geo-distribution'
                }
            },
            {
                name: 'reports.show',
                title: l.t('reports.total-calls', 'Total calls'),
                long_title: l.t('reports.total-calls-report', 'Total calls report'),
                params: {
                    type: 'total'
                }
            },
            {
                name: 'reports.show',
                title: l.t('reports.calls-per-extension', 'Calls per extension'),
                long_title: l.t('reports.calls-per-extension-report', 'Calls per extension report'),
                params: {
                    type: 'per-extension'
                }
            },
            {
                name: 'reports.show',
                title: l.t('reports.calls-per-number', 'Calls per number'),
                long_title: l.t('reports.calls-per-number-report', 'Calls per number report'),
                params: {
                    type: 'per-number'
                }
            }
        ],
        submenusRoutes: []
    };

    /**
     *
     */
    async getDefaultRoutes () {
        if (!this.allowed_routes.length) {
            for (const route of router) {
                if (await consoleUserCanAccessPageMiddleware(route, this.branding, this.session)) {
                    this.allowed_routes.push(route)
                }
            }
        }
        const routes = {
            categories: {
                'my-account': {
                    title: l.t('menu.my-account', 'My account'),
                    icon: '$vuetify.icons.person',
                    routes: this.take_category_routes('my-account'),
                    submenusRoutes: this.take_category_subroutes('my-account')
                },
                inbox: {
                    title: l.t('app.inbox', 'Inbox'),
                    icon: '$vuetify.icons.inbox',
                    routes: this.take_category_routes('inbox'),
                    submenusRoutes: this.take_category_subroutes('inbox')
                },
                people: {
                    title: l.t('app.people', 'People'),
                    icon: '$vuetify.icons.two_users',
                    routes: this.take_category_routes('people'),
                    submenusRoutes: this.take_category_subroutes('people')
                },
                'calls-handling': {
                    title: l.t('app.call-handling', 'Call handling'),
                    icon: '$vuetify.icons.calls_handling',
                    routes: this.take_category_routes('calls-handling'),
                    submenusRoutes: this.take_category_subroutes('calls-handling')
                },
                conferencing: {
                    title: l.t('app.conferencing', 'Conferencing'),
                    icon: '$vuetify.icons.screen',
                    routes: this.take_category_routes('conferencing'),
                    submenusRoutes: this.take_category_subroutes('conferencing')
                },
                reports: SidebarRouter.report_routes,
                'tool-box': {
                    title: l.t('app.toolbox', 'Toolbox'),
                    icon: '$vuetify.icons.opened_box',
                    routes: this.take_category_routes('tool-box'),
                    submenusRoutes: this.take_category_subroutes('tool-box')
                }
            },
            bottom_routes: this.take_category_routes('bottom-route'),
            single_routes: this.take_category_routes('single-route')
        }
        for (const cat of Object.keys(routes.categories)) {
            if (!routes.categories[cat].routes.length) {
                delete routes.categories[cat]
            }
        }

        return routes
    }

    /**
     * @param {string} category - category name
     */
    take_category_routes (category) {
        return this.allowed_routes.reduce((a, c) => {
            if (c && c.meta && c.meta.category === category) {
                a.push({
                    name: c.name,
                    title: c.meta.title(),
                    icon: c.meta.icon,
                    features: c.meta.features,
                    allowed_for_scopes: c.meta.allowed_for_scopes,
                    allowed_for_user_type: c.meta.alowed_for_user_type
                })
            }
            return a
        }, [])
    }

    /**
     * @param {string} category - category name
     */
    take_category_subroutes (category) {
        return this.allowed_routes.reduce((a, c) => {
            if (c && c.meta && c.meta.submenu === category) {
                a.push(c.name)
            }
            return a
        }, [])
    }

    /**
     *
     */
    async get_routes () {
        if (this._routes) return this._routes
        this.default_routes = await this.getDefaultRoutes()
        await this.checkAccountFeatures()
        let routes = {}
        if (this.branding.data.code === 'lapro') {
            routes = this.getLaProRoutes()
        } else {
            routes = this.default_routes
        }
        if (routes.categories) {
            for (const cat of Object.keys(routes.categories)) {
                if (routes.categories[cat].routes) {
                    routes.categories[cat].routes = routes.categories[cat].routes.reduce((a, c) => {
                        if (!a.find((x) => x.title === c.title)) a.push(c)
                        return a
                    }, [])
                }
            }
        }
        this._routes = routes

        return this._routes
    }

    /**
     *
     */
    getLaProRoutes () {
        const blocked_calls = this.default_routes.categories.people.routes.find((x) => x.name === 'blockedCalls.index')
        const addressbook = this.default_routes.categories.people.routes.find((x) => x.name === 'contacts.index')
        const routes = {
            categories: {
                'my-account': {
                    ...this.default_routes.categories['my-account'],
                    icon: '$vuetify.icons.calls_handling',
                    routes: this.default_routes.categories['my-account'].routes.filter(
                        (x) => x.name === 'call-logs'
                    )
                },
                reports: { ...this.default_routes.categories.reports }
            },
            single_routes: [
                {
                    ...blocked_calls,
                    icon: 'mdi-block-helper'
                },
                {
                    ...addressbook,
                    icon: '$vuetify.icons.persons'
                }
            ],
            bottom_routes: this.default_routes.bottom_routes
        }

        for (const c of Object.keys(routes.categories)) {
            if (routes.categories[c].routes && routes.categories[c].routes.length === 1) {
                routes.single_routes.unshift({
                    ...routes.categories[c].routes[0],
                    icon: routes.categories[c].icon
                })
                delete routes.categories[c]
            }
        }

        return routes
    }

    /**
     *
     */
    async checkAccountFeatures () {
        try {
            if (this.branding.data.routes.includes('subaccounts.index')) {
                if (!this.session.user.account.features['subaccounts-enabled']) {
                    this.default_routes.single_routes = this.default_routes.single_routes.filter((x) => x.name !== 'subaccounts.index')
                }
            }
            if (this.branding.data.routes.includes('trunks.index') && !this.session.user.account.features['trunks-enabled']) {
                this.default_routes.categories['my-account'].routes = this.default_routes.categories['my-account'].routes.filter((x) => x.name !== 'trunks.index')
            }
        } catch (err) {
            console.log('Something went wrong while loading account data.')
        }
        return true
    }
}

export default SidebarRouter
