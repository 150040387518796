import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8538
 *
 * @param {SvgIconProps} props - svg props
 */
export const CallIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M6.82705 10.6225C8.1268 13.4138 10.2969 15.731 12.9904 17.232L15.1969 15.1744C15.4677 14.9219 15.8584 14.8482 16.1935 14.9763C17.2664 15.3728 18.4323 15.6076 19.634 15.6495C20.167 15.6681 20.5878 16.1195 20.5692 16.6525L20.4511 20.0346C20.4325 20.5677 19.9812 20.9885 19.4482 20.9699C10.3483 20.6521 3.2309 13.0197 3.54868 3.91979C3.56729 3.38678 4.01862 2.96591 4.55163 2.98453L7.9435 3.10297C8.47651 3.12159 8.89737 3.57291 8.87876 4.10592C8.83646 5.3173 8.98967 6.487 9.31034 7.58492C9.40509 7.92783 9.31437 8.30307 9.03354 8.56495L6.82705 10.6225Z' fill={color}/>
        </SvgIcon>
    )
}

export default CallIcon
